<template>
  <div class="relative" ref="menuContainer">
    <img
      src="/icons/projects/action-dots.svg"
      class="w-5 h-5 pointer-events-auto cursor-pointer"
      @click.stop="toggleMenu"
    />
    <div
      v-if="showMenu"
      class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-20"
    >
      <ul>
        <li
          @click="handleAction('View')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          View expense detail
        </li>
        <li
          v-if="selectedType == 'Submitted'"
          @click="handleAction('Edit')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          Edit expense
        </li>
        <li
          v-if="selectedType == 'Submitted'"
          @click="handleAction('Delete')"
          class="px-4 py-2 cursor-pointer hover:bg-red-400 bg-red-200"
        >
          Delete expense
        </li>
      </ul>
    </div>
    <ModalEditExpense
      v-if="showModalExpenseEdit"
      :id_expense="item.id_expense"
      :projectId="item.id_project"
      :isOpen="showModalExpenseEdit"
      @close="showModalExpenseEdit = false"
    />
    <ModalDelete
      v-if="showDeletedModal"
      :from="'expense'"
      :item="item"
      :isOpen="showDeletedModal"
      @close="showDeletedModal = false"
    />
    <ModalViewExpense
      v-if="showModaExpenseView"
      :id_expense="item.id_expense"
      :status="item.status"
      :isOpen="showModaExpenseView"
      @close="showModaExpenseView = false"
    />
  </div>
</template>
    
    <script>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import menuState from "../../../const/taskMenuState";
import ModalDelete from "../DeleteModal.vue";
import ModalEditExpense from "./ExpensesEdit.vue";
import ModalViewExpense from "./ExpenseDetails.vue";
import { useStore } from "vuex";

export default {
  props: {
    item: Object,
  },
  components: {
    ModalEditExpense,
    ModalDelete,
    ModalViewExpense,
  },
  setup(props) {
    const store = useStore();
    const showMenu = ref(false);
    const showModalExpenseEdit = ref(false);
    const menuContainer = ref(null);
    const showDeletedModal = ref(false);
    const showModaExpenseView = ref(false);
    const selectedType = computed(() => store.getters.selectedType);

    const toggleMenu = () => {
      const newMenuState = !showMenu.value;
      menuState.setOpenMenuId(newMenuState ? props.item.id_expense : null);
    };

    const handleAction = (action) => {
      console.log(`Action: ${action}, Item: ${props.item.id_expense}`);
      if (action == "View") {
        toggleModalView();
      } else if (action == "Edit") {
        toggleModal();
      } else if (action == "Delete") {
        deleteExpense();
      }
      showMenu.value = false;
      menuState.setOpenMenuId(null);
    };

    const handleClickOutside = (event) => {
      if (menuContainer.value && !menuContainer.value.contains(event.target)) {
        showMenu.value = false;
        menuState.setOpenMenuId(null);
      }
    };

    watch(
      () => menuState.getOpenMenuId(),
      (newId) => {
        console.log("watch ....newId", newId);
        showMenu.value = newId === props.item.id_expense;
      }
    );

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    function toggleModal() {
      showModalExpenseEdit.value = !showModalExpenseEdit.value;
    }

    function toggleModalView() {
      showModaExpenseView.value = !showModaExpenseView.value;
    }

    async function deleteExpense() {
      showDeletedModal.value = !showDeletedModal.value;
    }

    return {
      showMenu,
      toggleMenu,
      handleAction,
      showModalExpenseEdit,
      ModalDelete,
      showDeletedModal,
      showModaExpenseView,
      menuContainer,
      selectedType,
    };
  },
};
</script>
    
    <style scoped>
/* Add necessary styles if needed */
</style>
    

  <template>
  <div
    v-if="localSnackbar.enabled"
    class="fixed bottom-4 left-1/2 transform -translate-x-1/2 max-w-sm w-full px-4 py-3 bg-gray-800 text-white rounded-lg shadow-lg flex justify-between items-center"
  >
    <span>{{ localSnackbar.text }}</span>
    <button
      @click="closeSnackbar"
      class="ml-4 text-red-400 hover:text-red-500 transition-colors"
    >
      Close
    </button>
  </div>
</template>
  
  <script>
import { computed, reactive, watch, onUnmounted } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    let timeoutId = null;

    const localSnackbar = reactive({
      text: "",
      enabled: false,
    });

    const snackbar = computed({
      get: () => store.state.app.snackbar,
      set: (value) => store.commit("setSnackbar", value),
    });

    const startAutoClose = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        closeSnackbar();
      }, 5000);
    };

    const closeSnackbar = () => {
      store.commit("clearSnackbar");
      localSnackbar.enabled = false;
      clearTimeout(timeoutId);
    };

    const clearTimeoutHandler = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    
    watch(
      () => snackbar.value.enabled,
      (newVal) => {
        localSnackbar.enabled = newVal;
        if (newVal) {
          startAutoClose();
          localSnackbar.text = snackbar.value.text;
        } else {
          clearTimeoutHandler();
        }
      }
    );

    
    onUnmounted(() => {
      clearTimeoutHandler();
    });

    return {
      localSnackbar,
      closeSnackbar,
    };
  },
};
</script>
  
  
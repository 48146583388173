<template>
  <LoaderSpinner v-if="flagLoading" />
  <div v-else-if="!isMobileView">
    <div
      class="w-full h-auto bg-white rounded-bl-[20px] rounded-br-[20px] justify-around items-start flex"
    >
      <!-- Headers -->
      <!-- <div class="w-[5%] flex-col justify-start items-start inline-flex"> -->
        <!-- ID Header -->
        <!-- <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-between items-center inline-flex"
        > -->
          <!-- <div class="justify-center items-center flex">
            <div
              class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
            ></div>
          </div> -->
          <!-- <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              ID
            </div>
          </div> -->
        <!-- </div> -->
        <!-- ID Data -->
        <!-- <div
          v-for="(item, index) in table"
          :key="index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <input
            type="checkbox"
            class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
          />
          <div
            class="grow shrink basis-0 text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.id_task }}
          </div>
        </div> -->
      <!-- </div>  -->

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Task Name Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Task Name
            </div>
          </div>
        </div>
        <!-- Task Name Data -->
        <div
          v-for="(item, index) in table"
          :key="'taskName-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <img
            src="/icons/projects/project-name.svg"
            class="w-10 h-10 p-2.5 bg-slate-100 rounded-[20px] justify-center items-center flex"
          />
          <div
            class="grow shrink basis-0 text-slate-600 text-left text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Task Description Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Description
            </div>
          </div>
        </div>
        <!-- Task Description Data -->
        <div
          v-for="(item, index) in table"
          :key="'taskName-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div
            class="grow shrink basis-0 text-slate-600 text-left text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.description }}
          </div>
        </div>
      </div>

      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Assigned To Header -->
        <div
          class="self-stretch h-11 px-2 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Assigned To
            </div>
          </div>
        </div>
        <!-- Assigned To Data -->
        <div
          v-for="(item, index) in table"
          :key="'assignedTo-' + index"
          class="self-stretch h-[72px] px-2 py-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div
            v-for="(item2, index2) in item.Users ? item.Users.slice(0, 5) : []"
            :key="'assignedTo-' + index2"
          >
            <img
              class="w-10 h-10 rounded-[200px]"
              :src="item2.photo"
              :title="item2.name + ' ' + item2.last_name"
            />
            <div class="flex-col justify-start items-start inline-flex">
              <div
                class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
              >
                {{ item2.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- Billable Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Billable
            </div>
          </div>
        </div>
        <!-- Billable Data -->
        <div
          v-for="(item, index) in table"
          :key="'billable-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            v-if="item.billable == 1"
            class="px-3 pt-1 mix-blend-multiply bg-emerald-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-emerald-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Yes
            </div>
          </div>
          <div
            v-else
            class="px-3 pt-1 mix-blend-multiply bg-red-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-red-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              No
            </div>
          </div>
        </div>
      </div>

      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Start Date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Start Date
            </div>
          </div>
        </div>
        <!-- Start Date Data -->
        <div
          v-for="(item, index) in table"
          :key="'startDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm text-left font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.start_date) }}
          </div>
        </div>
      </div>

      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Due Date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Due Date
            </div>
          </div>
        </div>
        <!-- Due Date Data -->
        <div
          v-for="(item, index) in table"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm text-left font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.due_date) }}
          </div>
        </div>
      </div>

      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Status Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex cursor-pointer"
          @click="toggleSortOrder"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Status
            </div>
            <div class="text-neutral-500">
              <span v-if="sortOrder === 'asc'">▲</span>
              <span v-else-if="sortOrder === 'desc'">▼</span>
              <span v-else>⇅</span>
            </div>
          </div>
        </div>
        <!-- Status Data -->
        <div
          v-for="(item, index) in table"
          :key="'status-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            v-if="item.status == 'Complete'"
            class="px-3 pt-1 mix-blend-multiply bg-emerald-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-emerald-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ item.status }}
            </div>
          </div>
          <div
            v-if="item.status == 'Missed'"
            class="px-3 pt-1 mix-blend-multiply bg-orange-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-orange-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ item.status }}
            </div>
          </div>
          <div
            v-if="item.status == 'Not Started'"
            class="px-3 pt-1 mix-blend-multiply bg-gray-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-gray-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ item.status }}
            </div>
          </div>
          <div
            v-if="item.status == 'In Progress'"
            class="px-3 pt-1 mix-blend-multiply bg-blue-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-blue-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ item.status }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- Actions Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Actions
            </div>
          </div>
        </div>
        <!-- Actions Data -->
        <div
          v-for="(item, index) in table"
          :key="'actions-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-center items-center inline-flex"
        >
          <DropdownMenu :item="item" />
        </div>
      </div>
    </div>
    <PPagination />
  </div>
  <div
    v-else
    class="p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full"
  >
    <!-- Card para cada elemento en la tabla -->
    <div
      v-for="(item, index) in table"
      :key="index"
      class="bg-white shadow-md rounded-2xl p-4 flex flex-col gap-3"
    >
      <!-- Actions -->
      <div class="mt-2 flex justify-end">
        <DropdownMenu :item="item" />
      </div>
      <!-- Task Name -->
      <div class="flex items-center gap-3">
        <img
          src="/icons/projects/project-name.svg"
          class="w-10 h-10 p-2.5 bg-slate-100 rounded-[20px]"
        />
        <div class="text-neutral-900 font-medium">{{ item.name }}</div>
      </div>

      <!-- Descripción -->
      <div class="text-slate-600 text-sm">
        <strong>Description:</strong> {{ item.description }}
      </div>

      <!-- Assigned To -->
      <div class="flex items-center gap-2">
        <strong class="text-slate-600 text-sm">Assigned To:</strong>
        <div class="flex -space-x-2">
          <div
            v-for="(user, userIndex) in item.Users.slice(0, 5)"
            :key="userIndex"
            class="relative inline-block"
          >
            <img
              :src="user.photo"
              class="w-6 h-6 rounded-full border border-white cursor-pointer"
              :style="{ left: `${userIndex * 14}px`, top: '0' }"
              @click="toggleTooltip(userIndex, item.id_task)"
              @mouseleave="hideTooltip(item.id_task)"
            />
            <div
              v-if="tooltipStates[item.id_task] === userIndex"
              class="absolute bottom-full mb-1 left-1/2 -translate-x-1/2 whitespace-nowrap bg-black text-white text-xs py-1 px-2 rounded-md"
            >
              {{ user.name + " " + user.last_name }}
            </div>
          </div>
        </div>
      </div>

      <!-- Start Date y Due Date -->
      <div class="text-slate-600 text-sm">
        <strong>Start Date:</strong>
        {{ helpers.formatDateToMMDDYYYY(item.start_date) }}
      </div>
      <div class="text-slate-600 text-sm">
        <strong>Due Date:</strong>
        {{ helpers.formatDateToMMDDYYYY(item.due_date) }}
      </div>

      <!-- Status -->
      <div
        class="px-3 py-1 rounded-2xl text-sm font-medium"
        :class="{
          'bg-emerald-50 text-emerald-700': item.status === 'Complete',
          'bg-orange-50 text-orange-700': item.status === 'Missed',
          'bg-gray-50 text-gray-700': item.status === 'Not Started',
          'bg-blue-50 text-blue-700': item.status === 'In Progress',
        }"
      >
        {{ item.status }}
      </div>
    </div>
    <PPagination />
  </div>
</template>
  
<script>
import PPagination from "../ProjectPagination.vue";
import DropdownMenu from "../Tasks/TaskDropDown.vue";
import LoaderSpinner from "../../CircularLoader.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import helpers from "../../../helpers";
export default {
  components: {
    PPagination,
    DropdownMenu,
    LoaderSpinner,
  },

  setup() {
    const store = useStore();
    const flagLoading = computed(() => store.getters.loading);
    const originalTable = computed(() => store.getters.table);
    const sortOrder = ref(null);
    const isMobileView = computed(() => store.getters.isMobileView);
    const tooltipStates = ref({});

    const toggleTooltip = (index, itemId) => {
      tooltipStates.value[itemId] =
        tooltipStates.value[itemId] === index ? null : index;
    };

    const hideTooltip = (itemId) => {
      tooltipStates.value[itemId] = null;
    };
    const table = computed(() => {
      if (!sortOrder.value) return originalTable.value;

      const sorted = [...originalTable.value];
      sorted.sort((a, b) => {
        if (sortOrder.value === "asc") {
          return a.status.localeCompare(b.status);
        } else if (sortOrder.value === "desc") {
          return b.status.localeCompare(a.status);
        }
      });
      return sorted;
    });

    const toggleSortOrder = () => {
      if (sortOrder.value === null) {
        sortOrder.value = "asc";
      } else if (sortOrder.value === "asc") {
        sortOrder.value = "desc";
      } else {
        sortOrder.value = null;
      }
    };
    return {
      table,
      flagLoading,
      helpers,
      sortOrder,
      toggleSortOrder,
      isMobileView,
      tooltipStates,
      toggleTooltip,
      hideTooltip,
    };
  },
};
</script>
  
  <style scoped>
</style>
  
<template>
  <div
    class="w-full h-10 px-6 justify-start items-center gap-4 inline-flex"
    v-if="!isMobileView"
  >
    <div class="flex flex-grow items-center w-1/2">
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Projects
      </div>
    </div>
    <div class="flex justify-end items-center w-1/2 gap-3">
      <div class="relative">
        <input
          type="text"
          placeholder=" Search projects..."
          v-model="searchQuery"
          @input="fetchFilteredProjects"
          class="px-20 py-[9px] bg-white rounded-lg border border-slate-200 pl-10 pr-2"
        />
        <img
          src="/icons/projects/search.svg"
          class="absolute left-3 top-3 w-5 h-5 pointer-events-none"
          style="pointer-events: none"
        />
      </div>
      <button
        class="px-4 py-[9px] bg-white rounded-lg shadow border border-slate-200 flex items-center gap-2"
      >
        <img src="/icons/projects/export.svg" class="w-5 h-5 relative" />
        <div
          class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Export
        </div>
      </button>
      <button
        class="px-4 py-[9px] bg-blue_primary rounded-lg shadow flex items-center gap-2"
        @click="newProject()"
      >
        <img
          src="/icons/projects/create-new-project.svg"
          class="w-5 h-5 relative"
        />
        <div
          class="text-white text-sm font-semibold font-['Poppins'] leading-snug"
        >
          Create New Project
        </div>
      </button>
    </div>
  </div>
  <div v-else class="w-full p-4 flex flex-col gap-4">
    <div
      class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
    >
      Projects
    </div>
    <button @click="toggleCollapse" class="p-2 bg-slate-100 rounded-md">
      <img
        :src="
          isCollapsed
            ? '/icons/global-timer/expand.svg'
            : '/icons/global-timer/collapse.svg'
        "
        class="w-5 h-5 ml-auto"
        alt="Toggle Collapse"
      />
    </button>

    <div v-show="!isCollapsed" class="flex flex-col gap-2">
      <div class="relative">
        <input
          type="text"
          placeholder="Search project..."
          v-model="searchQuery"
          @input="fetchFilteredProjects"
          class="w-full py-2 pl-10 pr-2 bg-white rounded-lg border border-slate-200"
        />
        <img
          src="/icons/projects/search.svg"
          class="absolute left-3 top-2.5 w-5 h-5 pointer-events-none"
        />
      </div>

      <button
        class="px-4 py-[9px] bg-white rounded-lg shadow border border-slate-200 flex items-center gap-2"
      >
        <img src="/icons/projects/export.svg" class="w-5 h-5 relative" />
        <div
          class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Export
        </div>
      </button>
    </div>
    <button
      v-if="usertype == 1"
      class="px-4 py-[9px] bg-blue_primary rounded-lg shadow flex gap-2 justify-center"
      @click="newProject()"
    >
     
      <div
        class="text-white text-sm font-semibold font-['Poppins'] leading-snug"
      >
        Create New Project
      </div>
      <img
        src="/icons/projects/create-new-project.svg"
        class="w-5 h-5 relative" 
      />
    </button>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { ref, computed } from "vue";
import debounce from "lodash.debounce";
import { useStore } from "vuex";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const isMobileView = computed(() => store.getters.isMobileView);
    const searchQuery = ref("");
    const isCollapsed = ref(true);
    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };
    const usertype = computed(() => store.state.user.type);

    function newProject() {
      router.push({ name: "create-new-project" });
    }
    const fetchProjects = async (query) => {
      try {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "projects",
          search: query,
        });
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    const debouncedFetchProjects = debounce(fetchProjects, 300);

    const fetchFilteredProjects = () => {
      debouncedFetchProjects(searchQuery.value);
    };
    return {
      newProject,
      fetchFilteredProjects,
      searchQuery,
      isMobileView,
      isCollapsed,
      toggleCollapse,
      usertype,
    };
  },
};
</script>
  
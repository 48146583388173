<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-[572px] bg-white rounded-xl shadow flex flex-col"
      v-else-if="!isMobileView"
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">Task Details</div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div class="p-4 flex flex-col gap-4">
        <div
          class="w-[572px] h-[26px] px-4 justify-between items-center inline-flex"
        >
          <div
            class="flex-col justify-center items-start text-left gap-1 inline-flex"
          >
            <div
              class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
            >
              {{ data.Project.name }}
            </div>
          </div>
          <div class="h-5 justify-end items-center gap-2 flex">
            <button @click="toggleModalEditTask">
              <img src="/icons/projects/edit.svg" class="w-5 h-5 relative" />
            </button>
            <button @click="deleteTask">
              <img
                src="/icons/projects/delete-task.svg"
                class="w-5 h-5 relative"
              />
            </button>
          </div>
        </div>
        <br />
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            {{ data.name }}
          </div>
        </div>
        <div class="flex justify-between gap-4">
          <div class="flex flex-col">
            <div class="text-slate-500 text-sm text-left font-normal">
              Created
            </div>
            <div class="text-neutral-900 text-sm text-left font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.start_date) }}
            </div>
          </div>

          <div class="flex flex-col">
            <div class="text-slate-500 text-left text-sm font-normal">
              Due Date
            </div>
            <div class="text-neutral-900 text-left text-sm font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.due_date) }}
            </div>
          </div>

          <div class="flex flex-col">
            <div class="text-slate-500 text-left text-sm font-normal">
              Assignees
            </div>
            <div class="flex gap-1 mt-1">
              <img
                v-for="(image, index) in data.Users.slice(0, 3)"
                :key="index"
                :src="image.photo"
                class="w-6 h-6 rounded-full"
                :title="image.name + ' ' + image.last_name"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">
            Description
          </div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            {{ data.description }}
          </div>
        </div>
      </div>
      <div v-if="flagStartTimer" class="mb-5">
        <LoaderSpinner />
      </div>

      <div
        v-if="data.status !== 'Complete' && !isOther && !flagStartTimer"
        class="mb-5"
      >
        <div
          v-if="!isTimerActive"
          class="w-[572px] h-10 px-10 flex-col justify-start items-end gap-2.5 inline-flex"
        >
          <button
            @click="addTimer()"
            class="self-stretch h-10 pl-4 pr-5 py-2.5 rounded-lg shadow border border-slate-500 justify-center items-center gap-2 inline-flex"
          >
            <img src="/icons/nav-bar/play.svg" class="w-5 h-5 relative" />
            <div
              class="text-slate-500 text-base font-medium font-['Inter'] leading-normal"
            >
              Start Time Tracker
            </div>
          </button>
        </div>
        <div
          v-else
          class="w-[492px] h-[66px] p-3 bg-slate-100 rounded-xl border border-slate-200 justify-between items-center inline-flex"
        >
          <div
            class="px-4 py-1.5 bg-slate-50 rounded-md border border-slate-200 justify-center items-center gap-2.5 flex"
          >
            <div
              class="text-neutral-900 text-xl font-medium font-['Public Sans'] leading-[30px]"
            >
              {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }} :
              {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }} :
              {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}
            </div>
          </div>
          <div class="justify-start items-center gap-3.5 flex">
            <button
              @click="startTimer"
              v-if="isPaused"
              class="w-7 h-7 p-0.5 bg-blue_primary rounded-[20px] justify-center items-center gap-2.5 flex"
            >
              <img
                src="/icons/global-timer/play.svg"
                class="w-[18px] h-[18px] relative"
              />
            </button>
            <button
              @click="pauseTimer"
              v-else
              class="w-7 h-7 p-0.5 bg-blue_primary rounded-[20px] justify-center items-center gap-2.5 flex"
            >
              <img
                src="/icons/global-timer/pause.svg"
                class="w-[18px] h-[18px] relative"
              />
            </button>
            <button @click="stopTimer" v-if="elapsedTimeOnlySeconds > 0">
              <img
                src="/icons/global-timer/stop.svg"
                class="w-8 h-8 relative"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-full max-w-sm md:max-w-lg bg-white rounded-xl shadow flex flex-col"
      v-else
    >
      <!-- Encabezado -->
      <div
        class="p-3 bg-slate-100 rounded-t-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div
          class="text-neutral-900 text-base md:text-lg font-semibold text-left"
        >
          Task Details
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-full flex items-center justify-center"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Contenido del modal -->
      <div class="p-4 flex flex-col gap-4">
        <div class="flex justify-between items-center">
          <div
            class="text-neutral-900 text-sm md:text-base font-medium text-left"
          >
            {{ data.Project.name }}
          </div>
          <div class="flex gap-2">
            <button @click="toggleModalEditTask">
              <img src="/icons/projects/edit.svg" class="w-5 h-5" />
            </button>
            <button @click="deleteTask">
              <img src="/icons/projects/delete-task.svg" class="w-5 h-5" />
            </button>
          </div>
        </div>

        <div
          class="text-neutral-900 text-sm md:text-base font-medium text-left"
        >
          {{ data.name }}
        </div>

        <div class="flex flex-wrap gap-4">
          <!-- Fecha de creación -->
          <div class="flex flex-col w-1/2 text-left">
            <div class="text-slate-500 text-xs md:text-sm">Created</div>
            <div class="text-neutral-900 text-sm font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.start_date) }}
            </div>
          </div>

          <!-- Fecha de entrega -->
          <div class="flex flex-col w-1/2 text-left">
            <div class="text-slate-500 text-xs md:text-sm">Due Date</div>
            <div class="text-neutral-900 text-sm font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.due_date) }}
            </div>
          </div>

          <!-- Asignados -->
          <div class="flex flex-col w-full text-left">
            <div class="text-slate-500 text-xs md:text-sm">Assignees</div>
            <!-- <div class="flex gap-1 mt-1">
              <img
                v-for="(image, index) in data.Users.slice(0, 3)"
                :key="index"
                :src="image.photo"
                class="w-6 h-6 rounded-full"
                :title="image.name + ' ' + image.last_name"
              />
            </div> -->
            <div class="flex -space-x-2">
              <div
                v-for="(user, index) in data.Users.slice(0, 5)"
                :key="index"
                class="relative inline-block"
              >
                <img
                  :src="user.photo"
                  class="w-6 h-6 rounded-full border border-white cursor-pointer"
                  :style="{ left: `${index * 14}px`, top: '0' }"
                  @click="toggleTooltip(index, user.id_user)"
                  @mouseleave="hideTooltip(user.id_user)"
                />
                <div
                  v-if="tooltipStates[user.id_user] === index"
                  class="absolute bottom-full mb-1 left-1/2 -translate-x-1/2 whitespace-nowrap bg-black text-white text-xs py-1 px-2 rounded-md"
                >
                  {{ user.name + " " + user.last_name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Descripción -->
        <div class="text-left">
          <div class="text-slate-500 text-xs md:text-sm">Description</div>
          <div class="text-neutral-900 text-sm md:text-base leading-relaxed">
            {{ data.description }}
          </div>
        </div>
      </div>
      <div
        v-if="data.status !== 'Complete' && !isOther && !flagStartTimer"
        class="mb-5"
      >
        <!-- Botón de Inicio del Temporizador -->
        <div v-if="!isTimerActive" class="p-4">
          <button
            @click="addTimer()"
            class="w-full h-10 pl-4 pr-5 py-2.5 rounded-lg shadow border border-slate-500 flex items-center justify-start gap-2"
          >
            <img src="/icons/nav-bar/play.svg" class="w-5 h-5" />
            <div class="text-slate-500 text-sm md:text-base font-medium">
              Start Time Tracker
            </div>
          </button>
        </div>
        <div
          v-else
          class="w-full max-w-sm h-auto p-2 bg-slate-100 rounded-xl border border-slate-200 flex justify-between items-center mx-auto"
        >
          <div
            class="px-3 py-1 bg-slate-50 rounded-md border border-slate-200 flex justify-center items-center"
          >
            <div
              class="text-neutral-900 text-lg font-medium font-['Public Sans'] leading-[24px]"
            >
              {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }} :
              {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }} :
              {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}
            </div>
          </div>

          <div class="flex items-center gap-2">
            <button
              @click="startTimer"
              v-if="isPaused"
              class="w-8 h-8 p-1 bg-blue_primary rounded-full flex justify-center items-center"
            >
              <img src="/icons/global-timer/play.svg" class="w-4 h-4" />
            </button>

            <button
              @click="pauseTimer"
              v-else
              class="w-8 h-8 p-1 bg-blue_primary rounded-full flex justify-center items-center"
            >
              <img src="/icons/global-timer/pause.svg" class="w-4 h-4" />
            </button>

            <button
              @click="stopTimer"
              v-if="elapsedTimeOnlySeconds > 0"
              class="w-15 h-15 p-1"
            >
              <img src="/icons/global-timer/stop.svg" class="w-8 h-8" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <StopTimerModal
      v-if="showModalStop"
      :isOpen="showModalStop"
      @close="showModalStop = false"
    />
    <ModalEditTask
      v-if="showModalEditTask"
      :id_task="data.id_task"
      :projectId="data.id_project"
      :isOpen="showModalEditTask"
      @close="showModalEditTask = false"
    />
    <ModalDelete
      v-if="showDeletedModal"
      :from="'task'"
      :item="data"
      :isOpen="showDeletedModal"
      @close="showDeletedModal = false"
    />
  </div>
</template>
  
  <script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../../CircularLoader.vue";
import helpers from "../../../helpers";
import StopTimerModal from "../../GlobalTimer/StopTimer.vue";
import ModalEditTask from "./TaskEdit.vue";
import ModalDelete from "../DeleteModal.vue";

export default {
  components: {
    LoaderSpinner,
    StopTimerModal,
    ModalEditTask,
    ModalDelete,
  },
  props: {
    isOpen: Boolean,
    id_task: String,
    isOther: Boolean,
  },
  emits: ["close"],

  setup(props, { emit }) {
    const store = useStore();
    const elapsedTimeOnlySeconds = computed(
      () => store.getters.elapsedTimeOnlySeconds
    );
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const project_details = computed(() => store.getters.detailsData);
    const isTimerActive = computed(() => store.getters.isTimerActive);

    const stopPushed = ref(null);
    const isPaused = computed(() => store.getters.isPaused);
    const flagLoading = ref(true);
    const data = ref("");
    const showModalStop = ref(false);
    const flagStartTimer = ref(false);
    const isMobileView = computed(() => store.getters.isMobileView);
    const showModalEditTask = ref(false);
    const showDeletedModal = ref(false);
    const tooltipStates = ref({});

    const toggleTooltip = (index, itemId) => {
      tooltipStates.value[itemId] =
        tooltipStates.value[itemId] === index ? null : index;
    };

    const hideTooltip = (itemId) => {
      tooltipStates.value[itemId] = null;
    };

    function closeModal() {
      emit("close");
    }

    function toggleModalStop() {
      showModalStop.value = !showModalStop.value;
    }

    function deleteTask() {
      showDeletedModal.value = !showDeletedModal.value;
    }

    const startTimer = async () => {
      await store.dispatch("startTimer", {
        startWithTime: elapsedTimeOnlySeconds.value,
      });
      await store.dispatch("updateElapsedTime");
    };

    const pauseTimer = async () => {
      await store.dispatch("pauseTimer", { fromButton: true });
    };

    const stopTimer = async () => {
      stopPushed.value = true;
      const previousElapsedTime = elapsedTimeOnlySeconds.value;

      const data = {
        ticket: selectedTakeaway.value.ticket,
        elapsedHours: formatTime(previousElapsedTime).elapsedHours,
        elapsedMinutes: formatTime(previousElapsedTime).elapsedMinutes,
        elapsedSeconds: formatTime(previousElapsedTime).elapsedSeconds,
        isGlobal: true,
        fromButton: true,
      };

      await store.dispatch("pauseTimer", data);
      toggleModalStop();
    };

    async function addTimer() {
      flagStartTimer.value = true;
      const data1 = {
        id_user: store.state.user.uid,
        id_project: project_details.value.id_project,
        id_task: data.value.id_task,
      };
      await store.dispatch("startTracker", data1).then(async (tracker) => {
        console.log("🚀 ~ awaitstore.dispatch ~ tracker:", tracker);
        if (tracker.data.existing == true) {
          const dataToStart = {
            startWithTime: tracker.data.dataValues.seconds_elapsed,
          };
          await store.dispatch("setSelectedTakeaway", {
            project_name: project_details.value.name,
            task_description: data.value.description,
            id_task: data.value.id_task,
            id_project: project_details.value.id_project,
          });
          await store.dispatch("startTimer", dataToStart);
        } else {
          await store.dispatch("setSelectedTakeaway", {
            project_name: project_details.value.name,
            task_description: data.value.description,
            id_task: data.value.id_task,
            id_project: project_details.value.id_project,
          });
          await store.dispatch("startTimer", {});
        }
      });
      flagStartTimer.value = false;
      closeModal();
    }
    function toggleModalEditTask() {
      showModalEditTask.value = !showModalEditTask.value;
    }

    onMounted(async () => {
      await store
        .dispatch("fetchTask", { id_task: props.id_task })
        .then((dataFetched) => {
          data.value = dataFetched;
          console.log("data.value....", data.value);
        });
      if (isTimerActive.value == true) {
        isPaused.value = false;
      }
      flagLoading.value = false;
    });

    // onSnapshot(userTimerDocRef, async (doc) => {
    //   console.log(
    //     "Se produjo un cambio en el documento de Firestore New TaskDetails.vue"
    //   );
    //   if (doc.exists()) {
    //     const dataDoc = doc.data();
    //     console.log("🚀 ~ onSnapshot ~ dataDoc: - taskDetails", dataDoc)
    //     const isNewPausedState = dataDoc.isTimerPaused;
    //     isPaused.value = isNewPausedState;

    //     if (dataDoc.stop) {
    //       isPaused.value = true;
    //       await store.dispatch("pauseTimer", { firestoreFlag: true });
    //       return;
    //     }
    //     if (dataDoc.isTimerActive) {
    //       if (!isNewPausedState) {
    //         console.log(' elapsed time in TaskDetails.vue.....', elapsedTimeOnlySeconds.value);
    //         await store.dispatch("startTimer", {
    //           startWithTime: elapsedTimeOnlySeconds.value,
    //           ticket: selectedTakeaway.value.ticket,
    //           firestoreFlag: true,
    //         });
    //         await store.dispatch("updateElapsedTime");
    //       } else {
    //         await store.dispatch("pauseTimer", { firestoreFlag: true });
    //       }
    //     }
    //   }
    // });

    const formatTime = (seconds) => {
      const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

      const elapsedHours = Math.floor(seconds / 3600);
      const elapsedMinutes = Math.floor((seconds % 3600) / 60);
      const elapsedSeconds = seconds % 60;

      return {
        elapsedHours: addLeadingZero(elapsedHours),
        elapsedMinutes: addLeadingZero(elapsedMinutes),
        elapsedSeconds: addLeadingZero(elapsedSeconds),
      };
    };
    return {
      closeModal,
      formatTime,
      startTimer,
      pauseTimer,
      stopTimer,
      elapsedTimeOnlySeconds,
      selectedTakeaway,
      isTimerActive,
      isPaused,
      flagLoading,
      data,
      helpers,
      toggleModalStop,
      showModalStop,
      addTimer,
      flagStartTimer,
      isMobileView,
      showModalEditTask,
      toggleModalEditTask,
      deleteTask,
      showDeletedModal,
      tooltipStates,
      toggleTooltip,
      hideTooltip,
    };
  },
};
</script>
  
  <style scoped>
</style>
  
<template>
  <div>
    <LoaderSpinner v-if="flagLoading" />
    <div class="table-container shadow-lg min-h-fit" v-else>
      <ClientManagementSearchFilters style="margin-bottom: 30px" />
      <ClientManagementTable style="margin-bottom: 30px" />
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted,  } from "vue";
import { useStore } from "vuex";
import ClientManagementTable from "@/components/ClientManagement/ClientsTable.vue";
import ClientManagementSearchFilters from "@/components/ClientManagement/ClientManagementSearchFilters.vue";
import LoaderSpinner from "../components/CircularLoader.vue";

export default {
  components: {
    ClientManagementTable,
    ClientManagementSearchFilters,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const selectedTab = ref("Employees");
    const flagLoading = ref(true);

    const handleTabSelected = (tab) => {
      selectedTab.value = tab;
    };

    onMounted(async () => {
      try {
        await store.commit("clearDetails");
        await store.commit("clearPagination");
        await store.commit("clearSelectedTab");
        await store.commit("clearClients");
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "clients",
        });
        flagLoading.value = false;
      } catch (error) {
        console.error("Error in getting clients:", error);
      }
    });

    

    return {
      selectedTab,
      handleTabSelected,
      flagLoading,
    };
  },
};
</script>
  
  <style scoped>
.table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
  
<template>
  <LoaderSpinner v-if="flagLoading" />
  <div
    v-else
    class="w-full sm:w-[70%] h-auto p-4 bg-white rounded-[20px] shadow flex-col justify-start items-start gap-5 inline-flex"
  >
    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Edit Resource
      </div>
    </div>

    <!-- photo -->
    <div class="w-full flex justify-center items-center gap-5">
      <div class="flex flex-col justify-center items-center gap-2">
        <img
          :src="photoPreview || details.photo || 'placeholder-image-url.jpg'"
          alt="Profile Photo"
          class="w-24 h-24 rounded-full object-cover"
        />
        <input
          type="file"
          accept="image/*"
          @change="handlePhotoUpload"
          class="hidden"
          ref="photoInput"
        />
        <button
          @click="triggerPhotoUpload"
          class="px-3 py-2 bg-blue_primary text-white rounded-lg shadow hover:bg-blue_primary"
        >
          Change Photo
        </button>
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- Client Name -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
        >
          Resource Name
        </div>
        <input
          v-model="details.name"
          type="text"
          placeholder="Name"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !details.name,
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              details.name,
          }"
        />
      </div>
      <!-- Client Last Name -->
      <div class="w-full flex-col text-left justify-start items-start gap-1.5">
        <div
          class="w-full text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Resource Last Name
        </div>
        <input
          v-model="details.last_name"
          type="text"
          placeholder="Last name"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !details.last_name,
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              details.last_name,
          }"
        />
      </div>
    </div>

    <div
      v-if="user_uid !== userId"
      class="w-full h-[74px] flex-col justify-start items-start gap-1.5 flex"
    >
      <div
        class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Resource Type
        </div>
        <select
          v-model="details.user_type_id"
          :class="{
            'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !details.user_type_id,
            'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              details.user_type_id,
          }"
        >
          <option value="" disabled>Select an option</option>
          <option
            v-for="option in userTypeOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Email
        </div>
        <input
          v-model="details.email"
          type="email"
          placeholder="mail@example.com"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid opacity-50 cursor-not-allowed':
              !isEmailValid(details.email),
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid opacity-50 cursor-not-allowed':
              isEmailValid(details.email),
          }"
          disabled
        />
      </div>

      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Phone Number
        </div>
        <input
          v-model="details.phone_number"
          type="tel"
          placeholder="phone number [XXX-XXX-XXXX]"
          @input="updatePhoneNumber"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !isPhoneNumberValid(details.phone_number),
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              isPhoneNumberValid(details.phone_number),
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- Street -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Street
        </div>
        <input
          v-model="details.street"
          type="text"
          placeholder="Street address"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !details.street,
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              details.street,
          }"
        />
      </div>

      <!-- Apartment/Unit -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Apartment/Unit
        </div>
        <input
          v-model="details.apartment_unit"
          type="text"
          placeholder="Apartment or unit number"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !details.apartment_unit,
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              details.apartment_unit,
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- City -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          City
        </div>
        <input
          v-model="details.city"
          type="text"
          placeholder="City"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !details.city,
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              details.city,
          }"
        />
      </div>

      <!-- State -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          State
        </div>
        <input
          v-model="details.state"
          type="text"
          placeholder="State"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !details.state,
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              details.state,
          }"
        />
      </div>

      <!-- Zipcode -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Zip Code
        </div>
        <input
          v-model="details.zipcode"
          type="text"
          placeholder="Zip code"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !details.zipcode,
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              details.zipcode,
          }"
        />
      </div>
    </div>
    <div class="w-full justify-end items-center gap-3 inline-flex">
      <button
        @click="handleCancel"
        class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
      >
        <div
          class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Cancel
        </div>
      </button>
      <button
        @click="handleUpdateClient"
        :disabled="!isFormValid"
        :class="
          !isFormValid
            ? 'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex'
            : 'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            !isFormValid
              ? 'text-white text-sm font-semibold  leading-snug'
              : 'text-white text-sm font-semibold  leading-snug'
          "
        >
          Update Resource
        </div>
      </button>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import LoaderSpinner from "../CircularLoader.vue";

export default {
  components: {
    LoaderSpinner,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const userId = route.params.id;
    const user_uid = computed(() => store.state.user.uid.toString());

    const phoneNumberDisplay = ref("");
    const flagLoading = ref(true);
    const details = computed(() => store.getters.detailsData);
    const userTypeOptions = computed(() => [
      { value: 2, label: "Employee" },
      { value: 3, label: "Contractor" },
    ]);

    const photoInput = ref(null);
    const photoPreview = ref(null);
    const selectedFile = ref(null);

    const isFormValid = computed(() => {
      return (
        details.value.name &&
        details.value.last_name &&
        details.value.user_type_id &&
        isEmailValid(details.value.email) &&
        isPhoneNumberValid(details.value.phone_number) &&
        details.value.street &&
        details.value.city &&
        details.value.state &&
        details.value.zipcode
      );
    });

    function isEmailValid(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    function isPhoneNumberValid(phone) {
      const re = /^\d{10}$/;
      return re.test(phone);
    }
    function updatePhoneNumber(event) {
      const input = event.target.value.replace(/\D/g, "");
      details.value.phone_number = input;
      if (input.length <= 3) {
        phoneNumberDisplay.value = input;
      } else if (input.length <= 6) {
        phoneNumberDisplay.value = `${input.slice(0, 3)}-${input.slice(3)}`;
      } else {
        phoneNumberDisplay.value = `${input.slice(0, 3)}-${input.slice(
          3,
          6
        )}-${input.slice(6)}`;
      }
    }

    const handleCancel = () => {
      router.push({
        name: "resource-management",
      });
    };

    function triggerPhotoUpload() {
      photoInput.value.click();
    }

    async function handlePhotoUpload(event) {
      const file = event.target.files[0];
      if (file) {
        selectedFile.value = file;
        const reader = new FileReader();
        reader.onload = () => {
          photoPreview.value = reader.result;
        };
        reader.readAsDataURL(file);
        details.value.photo = file;
      }
    }

    const handleUpdateClient = async () => {
      flagLoading.value = true;
      const formData = new FormData();
      formData.append("id_user", details.value.id_user);
      formData.append("name", details.value.name);
      formData.append("last_name", details.value.last_name);
      formData.append("user_type_id", details.value.user_type_id);
      formData.append("email", details.value.email);
      formData.append("phone_number", details.value.phone_number);
      formData.append("street", details.value.street);
      formData.append("apartment_unit", details.value.apartment_unit);
      formData.append("city", details.value.city);
      formData.append("state", details.value.state);
      formData.append("zipcode", details.value.zipcode);
      if (selectedFile.value) {
        formData.append("resources_profile_picture", selectedFile.value);
      }
      const response = await fetch("/new-kambuchi/users/edit-user", {
        method: "PUT",
        body: formData,
      });
      const editedUser = await response.json();
      if (editedUser.ok) {
        flagLoading.value = false;
        selectedFile.value = null;
        router.push({
          name: "resource-management",
          params: { id: details.value.id_client },
        });
      }
      flagLoading.value = false;
    };

    onMounted(async () => {
      if (Object.keys(details.value).length === 0) {
        await store.dispatch("fetchResource", { id_user: userId });
      }

      flagLoading.value = false;
    });

    return {
      phoneNumberDisplay,
      flagLoading,
      isFormValid,
      updatePhoneNumber,
      handleCancel,
      handleUpdateClient,
      details,
      isEmailValid,
      isPhoneNumberValid,
      userTypeOptions,
      handlePhotoUpload,
      triggerPhotoUpload,
      photoInput,
      photoPreview,
      selectedFile,
      user_uid,
      userId,
    };
  },
};
</script>
  
  <style scoped>
</style>
  
<template>
  <LoaderSpinner v-if="flagLoading" />
  <div v-else-if="!isMobileView">
    <div
      class="w-full h-auto bg-white rounded-bl-[20px] rounded-br-[20px] justify-around items-start flex"
    >
      <!-- Headers -->
      <!-- <div class="w-[5%] flex-col justify-start items-start inline-flex"> -->
        <!-- ID Header -->
        <!-- <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-between items-center inline-flex"
        >
          <div class="justify-center items-center flex">
            <div
              class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
            ></div>
          </div> -->
          <!-- <div class="justify-start items-center gap-1 flex">
              <div class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug">
                ID
              </div>
            </div> -->
        <!-- </div> -->
        <!-- ID Data -->
        <!-- <div
          v-for="(item, index) in table"
          :key="index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <input
            type="checkbox"
            class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
          /> -->
          <!-- <div class="grow shrink basis-0 text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug">
              {{ item.id }}
            </div> -->
        <!-- </div>
      </div> -->

      <div class="w-[35%] flex-col justify-start items-start inline-flex">
        <!-- Assigned To Header -->
        <div
          class="self-stretch h-11 px-2 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Assignee
            </div>
          </div>
        </div>
        <!-- Assigned To Data -->
        <div
          v-for="(item, index) in table"
          :key="'assignedTo-' + index"
          class="self-stretch h-[72px] px-2 py-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <img
            class="w-10 h-10 rounded-[200px]"
            :src="item.User ? item.User.photo : ''"
          />
          <div class="flex-col justify-start items-start inline-flex">
            <div
              class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
            >
              {{ item.User ? item.User.name + " " + item.User.last_name : "" }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Created Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Created Date
            </div>
          </div>
        </div>
        <!-- Start Date Data -->
        <div
          v-for="(item, index) in table"
          :key="'created_date-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 flex"
        >
          <div
            class="grow shrink basis-0 text-left text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.date_generated) }}
          </div>
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!--   Range Start Date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Range Start Date
            </div>
          </div>
        </div>
        <!--  Range Start Date -->
        <div
          v-for="(item, index) in table"
          :key="'range_start_date-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.range_start_date) }}
          </div>
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Range End Date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Range End Date
            </div>
          </div>
        </div>
        <!-- Range End Date -->
        <div
          v-for="(item, index) in table"
          :key="'range_end_date-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.range_end_date) }}
          </div>
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Total Time Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Total $
            </div>
          </div>
        </div>
        <!-- Total Time  Data -->
        <div
          v-for="(item, index) in table"
          :key="'total_time-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            $ {{ item.total_bill }}
          </div>
        </div>
      </div>

      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- Actions Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Actions
            </div>
          </div>
        </div>
        <!-- Actions Data -->
        <div
          v-for="(item, index) in table"
          :key="'actions-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-center items-center inline-flex"
        >
          <DropdownMenu :item="item" />
        </div>
      </div>
    </div>
    <PPagination />
  </div>
  <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
    <div
      v-for="(item, index) in table"
      :key="index"
      class="bg-white rounded-lg shadow-md p-4 flex flex-col gap-3"
    >
      <div class="flex justify-end ">
        <!-- <input
          type="checkbox"
          class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
        /> -->
        <DropdownMenu :item="item" />
      </div>

      <div class="flex items-center gap-3">
        <img
          class="w-10 h-10 rounded-full"
          :src="item.User ? item.User.photo : ''"
        />
        <div class="text-slate-600 text-sm font-medium">
          Assignee: {{ item.User ? item.User.name + " " + item.User.last_name : "" }}
        </div>
      </div>

      <div class="text-slate-600 text-sm">
        <span class="font-medium">Created Date:</span>
        {{ helpers.formatDateToMMDDYYYY(item.date_generated) }}
      </div>

      <div class="text-slate-600 text-sm">
        <span class="font-medium">Range Start Date:</span>
        {{ helpers.formatDateToMMDDYYYY(item.range_start_date) }}
      </div>

      <div class="text-slate-600 text-sm">
        <span class="font-medium">Range End Date:</span>
        {{ helpers.formatDateToMMDDYYYY(item.range_end_date) }}
      </div>

      <div class="text-slate-600 text-sm">
        <span class="font-medium">Total:</span> ${{ item.total_bill }}
      </div>
    </div>
    <PPagination />
  </div>
</template>
  
  
<script>
import PPagination from "../ProjectPagination.vue";
import DropdownMenu from "./BillsDropDown.vue";
import LoaderSpinner from "../../CircularLoader.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import helpers from "../../../helpers";

export default {
  components: {
    PPagination,
    DropdownMenu,
    LoaderSpinner,
  },

  setup() {
    const store = useStore();
    const table = computed(() => store.getters.table);
    const flagLoading = computed(() => store.getters.loading);
    const isMobileView = computed(() => store.getters.isMobileView);
    return {
      table,
      flagLoading,
      helpers,
      isMobileView,
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles if needed */
</style>
  
<template>
  <Login1 />
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Login1 from "../components/LoginComponents/LoginOne.vue";
export default {
  components: {
    Login1,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      if (store.state.loggedUser) {
        router.push({ name: "dashboard" });
      }
    });

    return {};
  },
};
</script>
<style module>
</style>

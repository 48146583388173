import user from './user'
import app from './app'
import tracker from './tracker'
import project from './project'
const worker = new Worker('/timeWorker.js')
import pagination from './pagination'
import actionDetails from './action-details'

export default {
  user,
  app,
  tracker,
  project,
  worker,
  pagination,
  actionDetails
}
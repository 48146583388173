<template>
  <div
    class="w-full h-auto px-6 pt-3 pb-4 border-t border-zinc-200"
    :class="
      isMobileView
        ? 'flex flex-col items-center gap-4'
        : 'inline-flex justify-between items-center'
    "
  >
    <!-- Información de la página -->
    <div
      class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
    >
      Page {{ pagination.currentPage }} of {{ pagination.totalPages }}
    </div>

    <!-- Controles de paginación -->
    <div
      :class="isMobileView ? 'flex gap-2 justify-center w-full' : 'flex gap-3'"
    >
      <button
        @click="previous"
        class="px-3.5 py-2 bg-white rounded-lg shadow border border-slate-300 flex justify-center items-center gap-2"
      >
        <div
          class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
        >
          Previous
        </div>
      </button>
      <button
        @click="next"
        class="px-3.5 py-2 bg-white rounded-lg shadow border border-slate-300 flex justify-center items-center gap-2"
      >
        <div
          class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
        >
          Next
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const currentPath = route.path;
    const routeId = route.params.id;

    const pagination = computed(() => store.getters.pagination);
    const selectedTab = computed(() => store.getters.selectedTab);
    const selectedType = computed(() => store.getters.selectedType);

    const isMobileView = computed(() => store.getters.isMobileView);

    async function next() {
      if (pagination.value.currentPage < pagination.value.totalPages) {
        await handlePagination("next");
      }
    }

    async function previous() {
      if (pagination.value.currentPage > 1) {
        await handlePagination("previous");
      }
    }

    async function handlePagination(direction) {
      const nextPage =
        direction === "next"
          ? pagination.value.currentPage + 1
          : pagination.value.currentPage - 1;

      let fetchPayload = {
        page: nextPage,
        limit: 10,
      };

      if (currentPath.includes("client-management")) {
        fetchPayload.type = "clients";
      } else if (currentPath.includes("resource-management")) {
        fetchPayload.type = "users";
        fetchPayload.searchType = selectedTab.value === "Employees" ? 2 : 3;
      } else if (currentPath.includes("client-desc")) {
        fetchPayload.type = selectedTab.value.toLowerCase();
        fetchPayload.id_client = routeId;
      } else if (currentPath.includes("projects")) {
        fetchPayload.type = "projects";
      } else if (currentPath.includes("project-details")) {
        const id_project = routeId;
        if (selectedTab.value === "Tasks") {
          fetchPayload.type = "tasks";
          fetchPayload.searchType =
            selectedType.value === "Active"
              ? ["In progress", "Not Started", "Missed"]
              : ["Complete"];
          fetchPayload.id_project = id_project;
        } else if (selectedTab.value === "Expenses") {
          fetchPayload.type = "expenses";
          fetchPayload.searchType = selectedType.value;
          fetchPayload.id_project = id_project;
        } else if (selectedTab.value === "Invoices") {
          fetchPayload.type = "invoices";
          fetchPayload.searchType = selectedType.value;
          fetchPayload.id_project = id_project;
        } else if (selectedTab.value === "Timesheets") {
          fetchPayload.type = "timesheets";
          fetchPayload.searchType = selectedType.value;
        } else if (selectedTab.value === "Bills") {
          fetchPayload.type = "bills";
          fetchPayload.searchType = selectedType.value;
        }
      }

      await store.dispatch("fetchTable", fetchPayload);
    }

    return {
      pagination,
      next,
      previous,
      isMobileView,
    };
  },
};
</script>

<template>
  <div>
    <LoaderSpinner v-if="flagLoading" />
    <div class="table-containerr" v-else>
      <ExpensesSearchFilters :isTab="true" style="margin-bottom: 30px" />
      <ExpensesTable style="margin-bottom: 30px" />
    </div>
  </div>
</template>
      
  <script>
import { ref, onMounted,  } from "vue";
import ExpensesTable from "@/components/Projects/Expenses/ExpensesTable.vue";
import ExpensesSearchFilters from "@/components/Projects/Expenses/ExpensesSearchFilters.vue";
import { useStore } from "vuex";
import LoaderSpinner from "../components/CircularLoader.vue";

export default {
  components: {
    ExpensesTable,
    ExpensesSearchFilters,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const flagLoading = ref(true);

    onMounted(async () => {
      try {
        await store.commit("clearDetails");
        await store.commit("clearPagination");
        await store.commit("clearSelectedTab");
        await store.commit("clearClients");
        await store.commit("clearSelectedProject");
        await store.commit("setSelectedType", "Submitted");
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "expenses",
          searchType: ["Submitted"],
        });
        flagLoading.value = false;
      } catch (error) {
        console.error("Error getting tasks:", error);
      }
    });

  

    return {
      flagLoading,
    };
  },
};
</script>
  
  <style scoped>
.table-container {
  display: flex;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
  
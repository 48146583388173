<template>
  <div
    v-if="!isMobileView"
    class="w-full h-[82px] px-4 pt-6 pb-4 justify-between items-center inline-flex"
  >
    <div
      class="p-1 bg-white rounded-lg border border-slate-100 justify-start items-start flex"
    >
      <button
        @click="selectType('Submitted')"
        :class="
          selectedType == 'Submitted'
            ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
            : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            selectedType == 'Submitted'
              ? 'text-blue_primary text-sm font-semibold leading-snug'
              : 'text-gray-500 text-sm font-normal leading-snug'
          "
        >
          Submitted
        </div>
      </button>
      <button
        @click="selectType('Approved')"
        :class="
          selectedType == 'Approved'
            ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
            : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            selectedType == 'Approved'
              ? 'text-emerald-700 text-sm font-semibold leading-snug'
              : 'text-gray-500 text-sm font-normal leading-snug'
          "
        >
          Approved
        </div>
      </button>
      <button
        @click="selectType('Rejected')"
        :class="
          selectedType == 'Rejected'
            ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
            : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            selectedType == 'Rejected'
              ? 'text-red-700 text-sm font-semibold leading-snug'
              : 'text-gray-500 text-sm font-normal leading-snug'
          "
        >
          Rejected
        </div>
      </button>
    </div>
    <div class="justify-start items-center gap-3 flex">
      <div class="relative">
        <input
          type="text"
          placeholder="Search invoices ...."
          v-model="searchQuery"
          @input="fetchFiltered"
          class="px-20 py-[9px] bg-white rounded-lg border border-slate-200 pl-10 pr-2"
        />
        <img
          src="/icons/projects/search.svg"
          class="absolute left-3 top-3 w-5 h-5 pointer-events-none"
          style="pointer-events: none"
        />
      </div>
      <button
        class="px-4 py-2.5 bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
      >
        <div
          class="text-slate-600 text-sm font-semibold font-['Inter'] leading-tight"
        >
          Filter
        </div>

        <img src="/icons/projects/filter.svg" class="w-5 h-5 relative" />
      </button>
      <button
        v-if="sidebar == false"
        @click="toggleModal()"
        class="px-4 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
      >
        <img
          src="/icons/projects/create-new-project.svg"
          class="w-5 h-5 relative"
        />
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Create Invoice
        </div>
      </button>
      <button
        v-else
        @click="newInvoicesView()"
        class="px-4 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
      >
        <img
          src="/icons/projects/create-new-project.svg"
          class="w-5 h-5 relative"
        />
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Create Invoice
        </div>
      </button>
    </div>
    <CreateNewInvoice
      :isTab="isTab"
      v-if="showModalCreateInvoice"
      :isOpen="showModalCreateInvoice"
      :id_project="id_project"
      @close="showModalCreateInvoice = false"
    />
  </div>
  <div v-else class="w-full p-4 flex flex-col gap-4">
    <div class="flex gap-2">
      <button
        @click="selectType('Submitted')"
        :class="[
          'flex-1 py-2 rounded-lg text-sm font-semibold',
          selectedType === 'Submitted'
            ? 'bg-blue_primary text-white'
            : 'bg-slate-100 text-gray-500',
        ]"
      >
        Submitted
      </button>
      <button
        @click="selectType('Approved')"
        :class="[
          'flex-1 py-2 rounded-lg text-sm font-semibold',
          selectedType === 'Approved'
            ? 'bg-emerald-700 text-white'
            : 'bg-slate-100 text-gray-500',
        ]"
      >
        Approved
      </button>
      <button
        @click="selectType('Rejected')"
        :class="[
          'flex-1 py-2 rounded-lg text-sm font-semibold',
          selectedType === 'Rejected'
            ? 'bg-red-700 text-white'
            : 'bg-slate-100 text-gray-500',
        ]"
      >
        Rejected
      </button>
    </div>

    <button @click="toggleCollapse" class="p-2 bg-slate-100 rounded-md">
      <img
        :src="
          isCollapsed
            ? '/icons/global-timer/expand.svg'
            : '/icons/global-timer/collapse.svg'
        "
        class="w-5 h-5 ml-auto"
        alt="Toggle Collapse"
      />
    </button>

    <div v-show="!isCollapsed" class="flex flex-col gap-2">
      <div class="relative">
        <input
          type="text"
          placeholder="Search invoices..."
          v-model="searchQuery"
          @input="fetchFiltered"
          class="w-full py-2 pl-10 pr-2 bg-white rounded-lg border border-slate-200"
        />
        <img
          src="/icons/projects/search.svg"
          class="absolute left-3 top-2.5 w-5 h-5 pointer-events-none"
        />
      </div>

      <button
        class="w-full py-2 bg-white rounded-lg shadow border border-slate-200 flex items-center justify-center gap-2"
      >
        <div class="text-slate-600 text-sm font-semibold">Filter</div>
        <img src="/icons/projects/filter.svg" class="w-5 h-5" />
      </button>

      <button
        v-if="sidebar == false"
        @click="toggleModal()"
        class="w-full py-2 bg-blue_primary rounded-lg text-white font-semibold flex items-center justify-center gap-2"
      >
        <img src="/icons/projects/create-new-project.svg" class="w-5 h-5" />
        Create Invoice
      </button>

      <button
        v-else
        @click="newInvoicesView()"
        class="w-full py-2 bg-blue_primary rounded-lg text-white font-semibold flex items-center justify-center gap-2"
      >
        <img src="/icons/projects/create-new-project.svg" class="w-5 h-5" />
        Create Invoice
      </button>
    </div>

    <CreateNewInvoice
      :isTab="isTab"
      v-if="showModalCreateInvoice"
      :isOpen="showModalCreateInvoice"
      :id_project="id_project"
      @close="showModalCreateInvoice = false"
    />
  </div>
</template>
<script>
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import CreateNewInvoice from "./InvoicesCreateNew.vue";
import { useStore } from "vuex";
import helpers from "../../../helpers";

export default {
  props: {
    isTab: Boolean,
  },
  components: {
    CreateNewInvoice,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const id_project = route.params.id;
    const store = useStore();
    const searchQuery = ref("");
    const showModalCreateInvoice = ref(false);
    const sidebar = ref(false);
    const flagShow = ref(false);
    const isMobileView = computed(() => store.getters.isMobileView);
    const selectedType = computed(() => store.getters.selectedType);
    const isCollapsed = ref(true);
    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    function toggleModal() {
      showModalCreateInvoice.value = !showModalCreateInvoice.value;
    }

    function newInvoicesView() {
      router.push({ name: "create-new-invoice" });
    }

    const selectType = async (type) => {
      await store.commit("setSelectedType", type);
      await store.dispatch("fetchTable", {
        page: 1,
        limit: 10,
        type: "invoices",
        searchType: type,
        id_project: id_project,
      });

      emit("type-selected-task", type);
    };

    const fetchFiltered = helpers.createDebouncedFetch(
      store,
      "invoices",
      selectedType,
      id_project
    );

    onMounted(async () => {
      try {
        flagShow.value = false;
        console.log(".....props.isTab...SearchFilters..", props.isTab);
        if (selectedType.value == null) {
          await store.commit("setSelectedType", "Submitted");
        }
        flagShow.value = true;
      } catch (error) {
        console.error("Error setting type:", error);
      }
    });

    return {
      toggleModal,
      showModalCreateInvoice,
      sidebar,
      newInvoicesView,
      id_project,
      selectType,
      flagShow,
      selectedType,
      searchQuery,
      fetchFiltered,
      isMobileView,
      isCollapsed,
      toggleCollapse,
    };
  },
};
</script>
<template>
  <LoaderSpinner v-if="flagLoading" />
  <div
    v-else-if="!isMobileView"
    class="w-100 h-[134px] px-4 py-6 bg-white justify-center items-start gap-6 inline-flex"
  >
    <div
      class="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex"
    >
      <div
        class="self-start text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        {{ data.description }}
      </div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div
          class="w-80 self-stretch flex-col justify-center items-start gap-3 inline-flex"
        >
          <div
            class="self-stretch justify-between items-center gap-2 inline-flex"
          >
            <div
              class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Progress
            </div>
            <div
              class="text-blue-700 text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              {{ progressPercentage }}%
            </div>
          </div>
          <div class="h-3 bg-slate-100 rounded-[100px] relative w-full">
            <div
              class="h-3 bg-blue_primary rounded-[10px] absolute top-0 left-0"
              :style="{ width: progressPercentage + '%' }"
            ></div>
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Time Allocated
          </div>
          <div
            class="text-neutral-900 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            {{
              `${
                data.total_time_worked
                  ? parseFloat(Number(data.total_time_worked).toFixed(2))
                  : 0
              } / ${data.time_allocated} hrs`
            }}
          </div>
        </div>
        <div class="flex-col justify-start items-start gap-0.5 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Status
          </div>
          <div
            v-if="data.status == 'Completed'"
            class="w-30 px-2 py-0.5 bg-slate-100 rounded-md flex-col justify-center items-start gap-2 flex"
          >
            <div
              class="text-emerald-700 text-xs font-medium font-['Public Sans'] leading-[18px]"
            >
              {{ data.status }}
            </div>
          </div>
          <div
            v-if="data.status == 'In Progress'"
            class="w-30 px-2 py-0.5 bg-slate-100 rounded-md flex-col justify-center items-start gap-2 flex"
          >
            <div
              class="text-purple-700 text-xs font-medium font-['Public Sans'] leading-[18px]"
            >
              {{ data.status }}
            </div>
          </div>
          <div
            v-if="data.status == 'Active'"
            class="w-30 px-2 py-0.5 bg-slate-100 rounded-md flex-col justify-center items-start gap-2 flex"
          >
            <div
              class="text-blue-700 text-xs font-medium font-['Public Sans'] leading-[18px]"
            >
              {{ data.status }}
            </div>
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Tasks
          </div>
          <div
            class="text-neutral-900 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            {{ data.total_completed_tasks }} / {{ data.total_tasks }}
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Created
          </div>
          <div
            class="text-neutral-900 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToDDMMMYYYY(data.start_date) }}
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Due Date
          </div>
          <div
            class="text-neutral-900 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToDDMMMYYYY(data.due_date) }}
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Employees
          </div>
          <div class="w-[67px] h-6 relative">
            <img
              v-for="(image, index) in imagesEmployees.slice(0, 5)"
              :key="index"
              :src="image.photo"
              class="w-6 h-6 absolute rounded-[200px]"
              :style="{ left: `${index * 14}px`, top: '0' }"
              :title="image.fullname"
            />
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Contractors
          </div>
          <div class="w-[67px] h-6 relative">
            <img
              v-for="(image, index) in imagesContractors.slice(0, 5)"
              :key="index"
              :src="image.photo"
              class="w-6 h-6 absolute rounded-[200px]"
              :style="{ left: `${index * 14}px`, top: '0' }"
              :title="image.fullname"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="justify-start items-start gap-4 flex">
      <button
        class="p-1.5 bg-slate-100 rounded-md justify-center items-center gap-2 flex"
      >
        <img src="/icons/projects/bookmark.svg" class="w-5 h-5 relative" />
      </button>
      <button
        class="p-1.5 bg-slate-100 rounded-md justify-center items-center gap-2 flex"
      >
        <img src="/icons/projects/share.svg" class="w-5 h-5 relative" />
      </button>
    </div>
  </div>
  <!-- Vista Mobile -->
  <div v-else class="w-full p-4 bg-white flex flex-col gap-4">
    <!-- Botones -->
    <div class="flex gap-2 justify-center">
      <button class="p-2 bg-slate-100 rounded-md">
        <img src="/icons/projects/bookmark.svg" class="w-5 h-5" />
      </button>
      <button class="p-2 bg-slate-100 rounded-md">
        <img src="/icons/projects/share.svg" class="w-5 h-5" />
      </button>
      <!-- Botón Collapse -->
      <button
        @click="toggleCollapse"
        class="p-2 bg-slate-100 rounded-md ml-auto"
      >
        <img
          :src="
            isCollapsed
              ? '/icons/global-timer/expand.svg'
              : '/icons/global-timer/collapse.svg'
          "
          class="w-5 h-5"
          alt="Toggle Collapse"
        />
      </button>
    </div>

    <!-- Descripción -->
    <div
      class="text-neutral-900 text-base font-semibold font-['Public Sans'] leading-6"
    >
      {{ data.description }}
    </div>

    <!-- Contenido colapsable -->
    <!-- Progreso -->
    <div class="flex flex-col gap-2">
      <div class="flex justify-between items-center">
        <span class="text-slate-500 text-sm font-medium">Progress</span>
        <span class="text-blue-700 text-sm font-semibold">
          {{ progressPercentage }}%
        </span>
      </div>
      <div class="h-3 bg-slate-100 rounded-[100px] relative w-full">
        <div
          class="h-3 bg-blue_primary rounded-[10px] absolute top-0 left-0"
          :style="{ width: progressPercentage + '%' }"
        ></div>
      </div>
    </div>
    <div v-show="!isCollapsed" class="flex flex-col gap-4">
      <!-- Tiempo asignado -->
      <div class="flex justify-between">
        <span class="text-slate-500 text-sm font-medium">Time Allocated</span>
        <span class="text-neutral-900 text-sm font-semibold">
          {{
            `${
              data.total_time_worked
                ? parseFloat(Number(data.total_time_worked).toFixed(2))
                : 0
            } / ${data.time_allocated} hrs`
          }}
        </span>
      </div>

      <!-- Estado -->
      <div class="flex justify-between">
        <span class="text-slate-500 text-sm font-medium">Status</span>
        <div
          class="px-2 py-0.5 bg-slate-100 rounded-md"
          :class="{
            'text-emerald-700': data.status === 'Completed',
            'text-purple-700': data.status === 'In Progress',
            'text-blue-700': data.status === 'Active',
          }"
        >
          <span class="text-xs font-medium">{{ data.status }}</span>
        </div>
      </div>

      <!-- Fecha de inicio -->
      <div class="flex justify-between">
        <span class="text-slate-500 text-sm font-medium">Created</span>
        <span class="text-neutral-900 text-sm font-semibold">
          {{ helpers.formatDateToDDMMMYYYY(data.start_date) }}
        </span>
      </div>

      <!-- Fecha de entrega -->
      <div class="flex justify-between">
        <span class="text-slate-500 text-sm font-medium">Due Date</span>
        <span class="text-neutral-900 text-sm font-semibold">
          {{ helpers.formatDateToDDMMMYYYY(data.due_date) }}
        </span>
      </div>

      <div class="flex justify-between items-center">
        <div
          class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
        >
          Employees
        </div>
        <div class="relative h-6" style="width: 100px">
          <div
            v-for="(image, index) in imagesEmployees.slice(0, 5)"
            :key="image.fullname"
            class="relative inline-block"
          >
            <img
              :src="image.photo"
              class="w-6 h-6 rounded-full border border-white cursor-pointer"
              :style="{ left: `${index * 14}px`, top: '0' }"
              @click="toggleTooltip(index, 'employee')"
              @mouseleave="hideTooltip('employee')"
            />
            <div
              v-if="tooltipIndexEmployees === index"
              class="absolute bottom-full mb-1 left-1/2 -translate-x-1/2 whitespace-nowrap bg-black text-white text-xs py-1 px-2 rounded-md"
            >
              {{ image.fullname }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-between items-center">
        <div
          class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
        >
          Contractors
        </div>
        <div class="relative h-6" style="width: 100px">
          <div
            v-for="(image, index) in imagesContractors.slice(0, 5)"
            :key="image.fullname"
            class="relative inline-block"
          >
            <img
              :src="image.photo"
              class="w-6 h-6 rounded-full border border-white cursor-pointer"
              :style="{ left: `${index * 14}px`, top: '0' }"
              @click="toggleTooltip(index, 'contractor')"
              @mouseleave="hideTooltip('contractor')"
            />
            <div
              v-if="tooltipIndexContractors === index"
              class="absolute bottom-full mb-1 left-1/2 -translate-x-1/2 whitespace-nowrap bg-black text-white text-xs py-1 px-2 rounded-md"
            >
              {{ image.fullname }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import helpers from "../../helpers";
import LoaderSpinner from "../CircularLoader.vue";
export default {
  components: {
    LoaderSpinner,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id;
    const store = useStore();
    const data = computed(() => store.getters.detailsData);
    const flagLoading = ref(false);
    const imagesEmployees = ref([]);
    const imagesContractors = ref([]);
    const id_user = computed(() => store.state.user.uid);
    const user_type = computed(() => store.state.user.type);
    const isMobileView = computed(() => store.getters.isMobileView);
    const isCollapsed = ref(true);
    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };
    const progressPercentage = computed(() => {
      const completed = data.value.total_completed_tasks || 0;
      const total = data.value.total_tasks || 1;
      return Math.round((completed / total) * 100);
    });
    const tooltipIndexEmployees = ref(null);
    const tooltipIndexContractors = ref(null);

    const toggleTooltip = (index, type) => {
      if (type === "employee") {
        tooltipIndexEmployees.value =
          tooltipIndexEmployees.value === index ? null : index;
      } else if (type === "contractor") {
        tooltipIndexContractors.value =
          tooltipIndexContractors.value === index ? null : index;
      }
    };

    const hideTooltip = (type) => {
      if (type === "employee") {
        tooltipIndexEmployees.value = null;
      } else if (type === "contractor") {
        tooltipIndexContractors.value = null;
      }
    };

    onMounted(async () => {
      flagLoading.value = true;
      const response = await store.dispatch("fetchProject", {
        id_project: id,
        id_user: id_user.value,
        user_type: user_type.value,
      });
      if (response.ok === false) {
        router.push({ name: "projects" });
        return;
      }

      if (data.value && Array.isArray(data.value.ProjectResources)) {
        for (const resource of data.value.ProjectResources) {
          if (resource.User && resource.User.photo) {
            if (resource.User.user_type_id === 2) {
              imagesEmployees.value.push({
                photo: resource.User.photo,
                fullname: resource.User.name + " " + resource.User.last_name,
              });
            } else if (resource.User.user_type_id === 3) {
              imagesContractors.value.push({
                photo: resource.User.photo,
                fullname: resource.User.name + " " + resource.User.last_name,
              });
            }
          }
        }
      }

      flagLoading.value = false;
    });

    return {
      data,
      helpers,
      flagLoading,
      imagesEmployees,
      imagesContractors,
      progressPercentage,
      isMobileView,
      isCollapsed,
      toggleCollapse,
      tooltipIndexEmployees,
      tooltipIndexContractors,
      toggleTooltip,
      hideTooltip,
    };
  },
};
</script>

<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-[572px] bg-white rounded-xl shadow flex flex-col max-h-[90vh] overflow-y-auto"
      v-else
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">Edit Expense</div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Expense Title
          </div>
          <input
            type="text"
            v-model="name"
            placeholder="Title"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !name,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                name,
            }"
          />
        </div>
      </div>
      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Expense Type
          </div>
          <input
            type="text"
            v-model="type"
            placeholder="Type"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !type,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                type,
            }"
          />
        </div>
      </div>
      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Expense Amount
          </div>
          <input
            type="number"
            v-model="amount"
            placeholder="150 bucks"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !amount,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                amount,
            }"
          />
        </div>
      </div>
      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Expense date
          </div>
          <div class="relative w-full">
            <input
              type="date"
              placeholder="dd/mm/yyyy"
              v-model="date"
              :class="{
                'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !date,
                'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  date,
              }"
            />
          </div>
        </div>
      </div>

      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Comments
          </div>
          <textarea
            v-model="comments"
            placeholder="Enter comments"
            class="w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid"
          ></textarea>
        </div>
      </div>
      <br />
      <div>
        <label class="block text-slate-600 text-base font-medium"
          >Expense Files</label
        >
        <div class="p-4 border rounded-lg border-zinc-200">
          <input
            type="file"
            @change="handleFileChange"
            multiple
            class="w-full px-3 py-2 bg-slate-50 rounded-lg border border-slate-200"
          />
          <LoaderSpinner v-if="flagLoadingFiles" />
          <div v-else class="mt-4 space-y-3">
            <div
              v-for="(file, index) in combinedFiles"
              :key="index"
              class="flex items-center justify-between"
            >
              <img
                v-if="file.url"
                :src="file.url"
                class="w-10 h-10 rounded-full"
                alt="File preview"
              />
              <div>{{ file.name }}</div>
              <button @click="removeFile(index, !file.id)" class="text-red-700">
                ✖
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Aplies for you and...
      </div>
      <div
        class="p-1 bg-white rounded-lg border border-slate-100 justify-start items-start flex"
      >
        <button
          @click="selectTab('Employees')"
          :class="
            selectedTab === 'Employees'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedTab === 'Employees'
                ? 'text-blue_primary text-sm font-semibold'
                : 'text-gray-500 text-sm font-normal'
            "
          >
            Employees
          </div>
        </button>
        <button
          @click="selectTab('Contractors')"
          :class="
            selectedTab === 'Contractors'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedTab === 'Contractors'
                ? 'text-blue_primary text-sm font-semibold'
                : 'text-gray-500 text-sm font-normal'
            "
          >
            Contractors
          </div>
        </button>
      </div>
      <div
        class="h-auto w-full px-4 flex-col justify-start items-start gap-5 flex"
      >
        <div
          class="h-auto w-full flex-col justify-start items-start gap-3 flex"
        >
          <div
            class="self-stretch h-auto p-4 rounded-lg border border-zinc-200 flex-col justify-start items-center gap-4 flex"
          >
            <input
              v-model="filter"
              type="text"
              placeholder="Search contributor..."
              class="self-stretch px-3 py-2 bg-slate-50 rounded-lg border border-slate-200 justify-start items-center gap-1 inline-flex"
            />
            <LoaderSpinner v-if="flagLoadingUsers" />
            <div
              v-else
              v-for="(user, index) in filteredUsers"
              :key="index"
              class="self-stretch justify-start items-center gap-4 inline-flex"
            >
              <div
                class="grow shrink basis-0 h-10 justify-start items-center gap-3 flex"
              >
                <div class="flex-col justify-start items-end inline-flex">
                  <img :src="user.photo" class="w-10 h-10 rounded-full" />
                </div>
                <div
                  class="grow shrink basis-0 flex-col justify-center items-start gap-0.5 inline-flex"
                >
                  <div
                    class="self-stretch text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
                  >
                    {{ user.name }} {{ user.last_name }}
                  </div>
                  <div
                    class="self-stretch text-slate-600 text-xs font-normal font-['Public Sans'] leading-[18px]"
                  >
                    {{ user.email }}
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'w-5 h-5 relative border-2 rounded cursor-pointer',
                  checkedUsers.includes(user.id_user)
                    ? 'bg-blue_primary'
                    : 'border-blue-700',
                ]"
                @click="toggleCheckbox(user.id_user)"
              >
                <div
                  v-if="checkedUsers.includes(user.id_user)"
                  class="absolute inset-0 flex items-center justify-center text-white"
                >
                  ✓
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 pt-2 pb-6 justify-end items-center gap-3 inline-flex">
        <button
          @click="closeModal"
          class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
        >
          <div
            class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Cancel
          </div>
        </button>

        <button
          @click="handleEditExpense()"
          :class="
            !isFormValid
              ? 'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex'
              : 'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              !isFormValid
                ? 'text-white text-sm font-semibold  leading-snug'
                : 'text-white text-sm font-semibold  leading-snug'
            "
          >
            Confirm
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
      
    <script>
import LoaderSpinner from "../../CircularLoader.vue";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import helpers from "../../../helpers";

export default {
  props: {
    isOpen: Boolean,
    projectId: Number,
    id_expense: Number,
  },
  components: { LoaderSpinner },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();

    const flagLoading = ref(false);
    const flagLoadingUsers = ref(false);
    const selectedTab = ref("Employees");
    const checkedUsers = ref([]);
    const users = ref([]);
    const filter = ref("");
    // const files = ref([]);
    const flagLoadingFiles = ref(false);

    const existingFiles = ref([]);
    const newFiles = ref([]);
    const deletedFiles = ref([]);

    const name = ref("");
    const type = ref("");
    const amount = ref("");
    const date = ref("");
    const comments = ref("");

    const typeOptions = ref(["Material", "Equipment", "Hotel"]);
    const selectedType = computed(() => store.getters.selectedType);

    const isFormValid = computed(() => {
      return name.value && type.value && amount.value && date.value;
    });

    function closeModal() {
      emit("close");
    }

    const selectTab = async (tab) => {
      flagLoadingUsers.value = true;
      const resources = await store.dispatch("fetchResourceForAssignTasks", {
        type: tab == "Employees" ? 2 : 3,
        id_project: props.projectId,
      });
      users.value = resources.data;
      selectedTab.value = tab;
      flagLoadingUsers.value = false;
    };

    function toggleCheckbox(userId) {
      const index = checkedUsers.value.indexOf(userId);
      if (index === -1) {
        checkedUsers.value.push(userId);
      } else {
        checkedUsers.value.splice(index, 1);
      }
    }

    const filteredUsers = computed(() => {
      const userArray = Object.values(users.value);
      return userArray
        .filter((user) =>
          user.name.toLowerCase().includes(filter.value.toLowerCase())
        )
        .slice(0, 5);
    });

    const handleFileChange = (event) => {
      const selectedFiles = Array.from(event.target.files);
      const validFiles = selectedFiles.filter(
        (file) => file.size <= 10 * 1024 * 1024
      ); // Máx. 10MB
      newFiles.value.push(...validFiles);
    };

    function removeFile(index, isNew) {
      if (isNew) {
        newFiles.value.splice(index, 1);
      } else {
        const fileToDelete = existingFiles.value[index];
        console.log("🚀 ~ removeFile ~ fileToDelete:", fileToDelete)
        existingFiles.value.splice(index, 1);
        deletedFiles.value.push({
          id: fileToDelete.id,
          file_url: fileToDelete.url, // Esto debe coincidir con la clave esperada en el backend
        });
        console.log(
          "🚀 ~ removeFile ~ deletedFiles.value:",
          deletedFiles.value
        );
      }
    }

    const formatSize = (size) => {
      if (size < 1024) return size + " bytes";
      else if (size < 1048576) return (size / 1024).toFixed(2) + " KB";
      else return (size / 1048576).toFixed(2) + " MB";
    };

    const combinedFiles = computed(() => [
      ...existingFiles.value,
      ...newFiles.value.map((file) => ({
        name: file.name,
        url: URL.createObjectURL(file), // Previsualización local
      })),
    ]);

    async function handleEditExpense() {
      if (!isFormValid.value) {
        return;
      }

      try {
        flagLoading.value = true;

        const formData = new FormData();
        formData.append("name", name.value);
        formData.append("id_project", props.projectId);
        formData.append("date", date.value);
        formData.append("comments", comments.value);
        formData.append("type", helpers.capitalize(type.value));
        formData.append("amount", amount.value);
        formData.append("id_expense", props.id_expense);
        formData.append("deleted_files", JSON.stringify(deletedFiles.value));

        // Adjunta nuevos archivos
        newFiles.value.forEach((file) => {
          formData.append("expenses_files", file);
        });

        // // Adjunta archivos a eliminar
        // deletedFiles.value.forEach((id) => {
        //   formData.append("deleted_files", id);
        // });

        const response = await fetch(`/new-kambuchi/expenses/edit-expense`, {
          method: "PUT",
          body: formData,
        });
        const result = await response.json();

        if (result.ok) {
          resetForm();
          await addResources(result.expense.id_expense);
          await store.dispatch("fetchTable", {
            page: 1,
            limit: 10,
            type: "expenses",
            searchType: selectedType.value,
            id_project: props.projectId,
          });
          closeModal();
        }
      } catch (error) {
        console.error("Error editing expense:", error);
      } finally {
        flagLoading.value = false;
      }
    }

    function resetForm() {
      name.value = "";
      type.value = "";
      amount.value = "";
      date.value = "";
      comments.value = "";
      existingFiles.value = [];
      newFiles.value = [];
      deletedFiles.value = [];
    }

    async function addResources(id_expense) {
      flagLoading.value = true;
      const data = {
        resources: checkedUsers.value,
        id_expense: id_expense,
      };
      const resp = await store.dispatch("addExpensesResources", data);
      if (resp.ok) {
        return true;
      }
    }

    onMounted(async () => {
      flagLoading.value = true;
      flagLoadingUsers.value = true;

      await store
        .dispatch("fetchExpense", { id_expense: props.id_expense })
        .then((dataFetched) => {
          name.value = dataFetched.name;
          type.value = dataFetched.type;
          amount.value = dataFetched.amount;
          comments.value = dataFetched.comments;
          date.value = dataFetched.date;

          existingFiles.value = dataFetched.ExpenseFiles.map((file) => {
            const fullName = file.file_url.split("/").pop().split("?")[0];
            const truncatedName =
              fullName.length > 30 ? `${fullName.slice(0, 27)}...` : fullName;

            return {
              id: file.id,
              name: truncatedName,
              url: file.file_url,
            };
          });
        });

      const resourcesInExpense = await store.dispatch(
        "fetchResourcesInExpense",
        {
          id_expense: props.id_expense,
        }
      );
      for (const resource of resourcesInExpense.data) {
        checkedUsers.value.push(resource.id_user);
      }

      const resources = await store.dispatch("fetchResourceForAssignTasks", {
        type: selectedTab.value == "Employees" ? 2 : 3,
        id_project: props.projectId,
      });
      users.value = resources.data;
      flagLoading.value = false;
      flagLoadingUsers.value = false;
    });
    return {
      closeModal,
      flagLoading,
      selectTab,
      checkedUsers,
      filteredUsers,
      filter,
      toggleCheckbox,

      flagLoadingFiles,
      handleFileChange,
      removeFile,
      formatSize,
      typeOptions,
      name,
      type,
      amount,
      date,
      comments,
      handleEditExpense,
      isFormValid,
      selectedTab,
      flagLoadingUsers,
      combinedFiles,
    };
  },
};
</script>
      
      <style scoped>
/* Add necessary styles */
</style>
      
<template>
    <div class="account-settings-container flex flex-col md:flex-row gap-5">
      <!-- Sidebar -->
      <aside
        class="w-full md:w-1/4 bg-white shadow rounded-lg p-4 flex flex-col gap-4"
      >
        <h2 class="text-lg font-semibold text-gray-800">Account Settings</h2>
        <ul class="flex flex-col gap-3">
          <li
            v-for="option in menuOptions"
            :key="option.key"
            @click="setActiveTab(option.key)"
            :class="{
              'px-4 py-2 rounded-lg cursor-pointer': true,
              'bg-blue-100 text-blue-600': activeTab === option.key,
              'hover:bg-gray-100 text-gray-700': activeTab !== option.key,
            }"
          >
            {{ option.label }}
          </li>
        </ul>
      </aside>
  
      <!-- Content -->
      <section
        class="flex-1 bg-white shadow rounded-lg p-4"
      >
        <component :is="getCurrentTabComponent" />
      </section>
    </div>
  </template>
  
  <script>
  import { ref, computed } from "vue";
  
  // Tab Components
  // import ProfileSettings from "./ProfileSettings.vue";
  // import SecuritySettings from "./SecuritySettings.vue";
  import ServiceCatalog from "@/components/AccountSettings/ServicesCatalog.vue";
  
  export default {
    components: {
      // ProfileSettings,
      // SecuritySettings,
      ServiceCatalog,
    },
    setup() {
      // Define menu options
      const menuOptions = [
        // { key: "profile", label: "Profile Settings" },
        // { key: "security", label: "Security Settings" },
        { key: "catalog", label: "Service Catalog" },
      ];
  
      // Manage active tab state
      const activeTab = ref(menuOptions[0].key);
  
      // Function to update active tab
      const setActiveTab = (tabKey) => {
        activeTab.value = tabKey;
      };
  
      // Dynamically render the component for the active tab
      const getCurrentTabComponent = computed(() => {
        switch (activeTab.value) {
          // case "profile":
          //   return ProfileSettings;
          // case "security":
          //   return SecuritySettings;
          case "catalog":
            return ServiceCatalog;
          default:
            return ServiceCatalog;
        }
      });
  
      return {
        menuOptions,
        activeTab,
        setActiveTab,
        getCurrentTabComponent,
      };
    },
  };
  </script>
  
  <style scoped>
  .account-settings-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  </style>
  
<template>
  <div
    v-if="!isMobileView"
    class="w-full h-10 px-4 pt-2 border-b border-slate-200 flex gap-4"
  >
    <button
      v-for="tab in visibleTabs"
      :key="tab"
      @click="selectTab(tab)"
      class="flex flex-col items-start gap-2.5"
    >
      <div :class="tabClasses(tab)" class="text-sm leading-snug">
        {{ tab }}
      </div>
      <div
        :class="[
          'h-0.5 w-full',
          {
            'bg-blue_primary': tab === selectedTab,
            'opacity-0': tab !== selectedTab,
          },
        ]"
      ></div>
    </button>
  </div>
  <!-- Vista Mobile -->
  <div
    v-else
    class="w-full border-b border-slate-200 flex gap-2 "
  >
    <button
      v-for="tab in visibleTabs"
      :key="tab"
      @click="selectTab(tab)"
      class="whitespace-nowrap py-1 px-1  text-xs rounded-md"
      :class="{
        'bg-blue_primary text-white font-semibold': tab === selectedTab,
        'bg-slate-100 text-slate-600 font-normal': tab !== selectedTab,
      }"
    >
      {{ tab }}
    </button>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup(_, { emit }) {
    const store = useStore();
    const selectedTab = computed(() => store.getters.selectedTab);
    const isMobileView = computed(() => store.getters.isMobileView);
    const user_type = computed(() =>
      store.state.user ? store.state.user.type : null
    );

    const visibleTabs = computed(() => {
      const allTabs = ["Tasks", "Expenses", "Invoices", "Bills", "Timesheets"];
      if (user_type.value === 2) {
        return allTabs.filter((tab) => tab !== "Invoices" && tab !== "Bills");
      }
      if (user_type.value === 3) {
        return allTabs.filter(
          (tab) => tab !== "Timesheets" && tab !== "Invoices"
        );
      }
      return allTabs;
    });

    function selectTab(tab) {
      selectedTab.value = tab;
      emit("tab-selected", tab);
    }

    function tabClasses(tab) {
      return tab === selectedTab.value
        ? "text-blue_primary font-semibold"
        : "text-slate-600 font-normal";
    }

    return {
      tabClasses,
      selectTab,
      selectedTab,
      visibleTabs,
      isMobileView,
    };
  },
};
</script>

<style scoped>
</style>

<template>
  <div
    class="sm:w-[70%] h-auto p-4 bg-white rounded-[20px] shadow flex-col justify-start items-start gap-5 inline-flex"
  >
    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Create New Service
      </div>
    </div>

    <!-- Service Name -->
    <div class="w-full flex-col justify-start items-start gap-1.5">
      <div
        class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Service Name
      </div>
      <input
        v-model="serviceName"
        type="text"
        placeholder="Service name"
        :class="{
          'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
            !serviceName,
          'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
            serviceName,
        }"
      />
    </div>

    <!-- Service Description -->
    <div class="w-full flex-col justify-start items-start gap-1.5">
      <div
        class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Description
      </div>
      <textarea
        v-model="serviceDescription"
        placeholder="Description (optional)"
        class="w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-slate-200"
        maxlength="200"
      ></textarea>
      <div class="text-right text-sm text-slate-500 mt-1">
        {{ serviceDescription.length }}/200
      </div>
    </div>

    <!-- Add or edit button -->
    <div class="w-full justify-end items-center gap-3 inline-flex">
      <LoaderSpinner v-if="flagLoading" />
      <button
        v-else
        @click="handleCreateService"
        :disabled="!isFormValid"
        :class="
          !isFormValid
            ? 'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex'
            : 'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex'
        "
      >
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          {{ flagEditing ? "Confirm edit" : "Create Service" }}
        </div>
      </button>
    </div>

    <!-- Existing services list -->
    <div class="self-stretch mt-4">
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7 mb-4"
      >
        Existing Services
      </div>

      <!-- Existing services search -->
      <div
        class="self-stretch mt-6 flex flex-col sm:flex-row items-center gap-4"
      >
        <input
          type="text"
          placeholder="Search services..."
          v-model="searchQuery"
          @input="handleSearch"
          class="w-full sm:w-1/2 px-3 py-[9px] bg-white rounded-lg border border-slate-200 shadow mb-5"
        />
      </div>

      <ul class="space-y-4 w-full">
        <li
          v-for="service in services"
          :key="service.id_service"
          class="p-4 bg-gray-100 rounded-lg flex flex-col gap-2"
        >
          <!-- Service -->
          <div class="w-full">
            <div class="text-gray-800 font-medium text-left">
              {{ service.name }}
            </div>
            <div class="text-gray-600 text-sm text-left">
              {{ service.description }}
            </div>
          </div>

          <!-- Action buttons -->
          <div class="flex gap-2 justify-end">
            <button
              @click="editService(service)"
              class="px-3 py-1 text-white hover:bg-blue-400 rounded"
            >
              <img src="/icons/projects/edit.svg" class="w-5 h-5" />
            </button>
            <button
              @click="deleteService(service.id_service)"
              class="px-3 py-1 text-white hover:bg-red-400 rounded"
            >
              <img src="/icons/projects/delete-task.svg" class="w-5 h-5" />
            </button>
          </div>
        </li>
      </ul>

      <!-- Pagination -->
      <div class="mt-4 flex justify-between items-center">
        <button
          @click="previousPage"
          :disabled="currentPage === 1"
          class="px-3.5 py-2 bg-white rounded-lg shadow border border-slate-300 flex justify-center items-center gap-2"
        >
          <div
            class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
          >
            Previous
          </div>
        </button>
        <div
          class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
        >
          Page {{ currentPage }} of {{ totalPages }}
        </div>
        <button
          @click="nextPage"
          :disabled="currentPage === totalPages"
          class="px-3.5 py-2 bg-white rounded-lg shadow border border-slate-300 flex justify-center items-center gap-2"
        >
          <div
            class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
          >
            Next
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import LoaderSpinner from "../CircularLoader.vue";

export default {
  components: {
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    // const router = useRouter();
    const serviceName = ref("");
    const serviceDescription = ref("");
    const flagLoading = ref(false);
    const flagEditing = ref(false);
    const services = ref([]);
    const edit_id = ref();
    const searchQuery = ref("");
    const debounceTimeout = ref(null);
    //pagination
    const currentPage = ref(1);

    const totalItems = ref(0);
    const itemsPerPage = ref(10);
    const totalPages = computed(() =>
      Math.ceil(totalItems.value / itemsPerPage.value)
    );
    function previousPage() {
      if (currentPage.value > 1) {
        currentPage.value--;
        fetchFiltered();
      }
    }
    function nextPage() {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        fetchFiltered();
      }
    }

    const isFormValid = computed(() => serviceName.value.trim() !== "");

    async function fetchFiltered() {
      try {
        flagLoading.value = true;
        const result = await store.dispatch("fetchAllServices", {
          query: searchQuery.value,
          page: (currentPage.value - 1) * itemsPerPage.value,
          limit: itemsPerPage.value,
        });
        if (result.ok) {
          services.value = result.services;
          totalItems.value = result.total;
        }
      } catch (error) {
        console.error("Error fetching filtered services:", error);
      } finally {
        flagLoading.value = false;
      }
    }

    function handleSearch() {
      flagLoading.value = true;
      if (debounceTimeout.value) {
        clearTimeout(debounceTimeout.value);
      }
      debounceTimeout.value = setTimeout(() => {
        fetchFiltered();
        flagLoading.value = false;
      }, 2000);
    }

    async function handleCreateService() {
      if (!isFormValid.value) return;

      try {
        flagLoading.value = true;

        if (flagEditing.value === true) {
          const editService = {
            id_service: edit_id.value,
            name: serviceName.value.trim(),
            description: serviceDescription.value.trim(),
          };

          await store.dispatch("editService", editService);

          serviceName.value = "";
          serviceDescription.value = "";
          flagEditing.value = false;
          await getServices();
        } else {
          const newService = {
            name: serviceName.value.trim(),
            description: serviceDescription.value.trim(),
          };

          await store.dispatch("addService", newService);

          serviceName.value = "";
          serviceDescription.value = "";
          await getServices();
        }
      } catch (error) {
        console.error("Error creating service:", error);
      } finally {
        flagLoading.value = false;
      }
    }

    async function deleteService(serviceId) {
      try {
        await store.dispatch("deleteService", serviceId);
        await getServices();
      } catch (error) {
        console.error("Error deleting service:", error);
      }
    }

    function editService(service) {
      edit_id.value = service.id_service;
      flagEditing.value = true;
      serviceName.value = service.name;
      serviceDescription.value = service.description;
    }

    async function getServices() {
      const result = await store.dispatch("fetchAllServices");
      if (result.ok) {
        services.value = result.services;
      }
    }

    onMounted(async () => {
      fetchFiltered();
    });

    return {
      serviceName,
      serviceDescription,
      isFormValid,
      handleCreateService,
      deleteService,
      editService,
      flagLoading,
      flagEditing,
      services,
      fetchFiltered,
      searchQuery,
      handleSearch,
      previousPage,
      nextPage,
      currentPage,
      totalItems,
      itemsPerPage,
      totalPages,
    };
  },
};
</script>

export default {
  appLoaded: false,
  snackbar: {
    text: "",
    enabled: false,
  },
  loader: false,
  drawer: false,
  isMobileView:  window.innerWidth <= 768,
};

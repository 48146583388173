<template>
  <div class="relative" ref="menuContainer">
    <img
      src="/icons/projects/action-dots.svg"
      class="w-5 h-5 pointer-events-auto cursor-pointer"
      @click.stop="toggleMenu"
    />
    <div
      v-if="showMenu"
      class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-10"
    >
      <ul>
        <li
          @click="handleAction('View')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          View Project Details
        </li>
        <li
          v-if="user_type == 1"
          @click="handleAction('Assign')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          Assign Resources
        </li>
        <li
          v-if="user_type == 1"
          @click="handleAction('Add-Task')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          Add new task
        </li>
      
        
      </ul>
    </div>
    <ModalChangeStatus
      v-if="showModalChangeStatus"
      :isOpen="showModalChangeStatus"
      @close="showModalChangeStatus = false"
    />

    <AssignResources
      v-if="showAssignResources"
      :isOpen="showAssignResources"
      :projectId="item.id_project"
      @close="showAssignResources = false"
    />
    <CreateNewTask
      v-if="showModalCreateTask"
      :isOpen="showModalCreateTask"
      :projectId="item.id_project"      
      :project_start="item.start_date"
      :project_end="item.due_date"
      @close="showModalCreateTask = false"
    />
  </div>
</template>
    
    <script>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import menuState from "../../const/taskMenuState";
import ModalChangeStatus from "./Tasks/TaskEditStatus.vue";
import AssignResources from "../Projects/AssingResources.vue";
import CreateNewTask from "../Projects/Tasks/TaskCreateNewTask.vue";

export default {
  props: {
    item: Object,
  },
  components: {
    ModalChangeStatus,
    AssignResources,
    CreateNewTask,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const showMenu = ref(false);
    const showModalChangeStatus = ref(false);
    const showModalCreateTask = ref(false);
    const showModalTaskDetail = ref(false);
    const menuContainer = ref(null);
    const showAssignResources = ref(false);
    const user_type = computed(() => store.state.user.type);

    const toggleMenu = () => {
      const newMenuState = !showMenu.value;
      menuState.setOpenMenuId(newMenuState ? props.item.id_project : null);
    };

    const handleAction = (action) => {
      console.log(`Action: ${action}, Item: ${props.item.id_project}`);
      if (action == "Change") {
        toggleModal();
      } else if (action == "View") {
        toggleProjectDetail();
      } else if (action == "Assign") {
        toggleModalAssign();
      } else if (action == "Add-Task") {
        toggleCreateTask();
      }

      showMenu.value = false;
      menuState.setOpenMenuId(null);
    };

    const handleClickOutside = (event) => {
      if (menuContainer.value && !menuContainer.value.contains(event.target)) {
        showMenu.value = false;
        menuState.setOpenMenuId(null);
      }
    };
  
    watch(
      () => menuState.getOpenMenuId(),
      (newId) => {
        showMenu.value = newId === props.item.id_project;
      }
    );

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    function toggleModal() {
      showModalChangeStatus.value = !showModalChangeStatus.value;
    }
    function toggleModalAssign() {
      showAssignResources.value = !showAssignResources.value;
    }
    function toggleCreateTask() {
      showModalCreateTask.value = !showModalCreateTask.value;
    }
    async function toggleProjectDetail() {
      await store.commit("setSelectedProject", {
        id_project: props.item.id_project,
        name: props.item.name,
      });
      router.push({
        name: "project-details",
        params: { id: props.item.id_project },
      });
    }

    return {
      showMenu,
      toggleMenu,
      handleAction,
      showModalChangeStatus,
      showModalTaskDetail,
      menuContainer,
      showAssignResources,
      user_type,
      showModalCreateTask,
    };
  },
};
</script>
    
    <style scoped>
</style>
    
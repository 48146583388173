<template>
  <LoaderSpinner v-if="flagLoading" />
  <div v-else>
    <div
      v-if="!isMobileView"
      class="w-full h-auto bg-white rounded-bl-[20px] rounded-br-[20px] justify-around items-start flex"
    >
      <!-- Headers -->
      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- ID Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-between items-center inline-flex"
        >
          <div class="justify-center items-center flex">
            <div
              class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
            ></div>
          </div>
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              ID
            </div>
          </div>
        </div>
        <!-- ID Data -->
        <div
          v-for="(item, index) in table"
          :key="index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <input
            type="checkbox"
            class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
          />
          <div
            class="grow shrink basis-0 text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.id_project }}
          </div>
        </div>
      </div>

      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Project Name Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Project Name
            </div>
          </div>
        </div>
        <!-- Project Name Data -->
        <div
          v-for="(item, index) in table"
          :key="'projectName-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 flex"
        >
          <img
            src="/icons/projects/project-name.svg"
            class="w-10 h-10 p-2.5 bg-slate-100 rounded-[20px] justify-center items-center flex"
          />

          <div
            class="grow shrink basis-0 text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Client Header -->
        <div
          class="self-stretch h-11 px-2 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Client
            </div>
          </div>
        </div>
        <!-- Client Data -->
        <div
          v-for="(item, index) in table"
          :key="'client-' + index"
          class="self-stretch h-[72px] px-2 py-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <img
            class="w-10 h-10 rounded-[200px]"
            :src="item.Client && item.Client.photo ? item.Client.photo : ''"
          />
          <div class="flex-col justify-start items-start inline-flex">
            <div
              class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
            >
              {{
                item.Client
                  ? item.Client.name + " " + item.Client.last_name
                  : ""
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Address Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Address
            </div>
          </div>
        </div>
        <!-- Address Data -->
        <div
          v-for="(item, index) in table"
          :key="'address-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="grow shrink basis-0 text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.street }} {{ item.apartment_unit }} {{ item.city }},
            {{ item.state }} {{ item.zipcode }}
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Timeline Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Timeline
            </div>
          </div>
        </div>
        <!-- Timeline Data -->
        <div
          v-for="(item, index) in table"
          :key="'timeline-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div class="flex items-center">
            <span
              class="text-red-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
              >60</span
            >
            <span
              class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
              >/ {{ item.time_allocated }} hr</span
            >
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Start Date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Start Date
            </div>
          </div>
        </div>
        <!-- Start Date Data -->
        <div
          v-for="(item, index) in table"
          :key="'startDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.start_date) }}
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Due Date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Due Date
            </div>
          </div>
        </div>
        <!-- Due Date Data -->
        <div
          v-for="(item, index) in table"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.due_date) }}
          </div>
        </div>
      </div>

      <div class="w-[10%] o flex-col justify-start items-start inline-flex">
        <!-- Status Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Status
            </div>
          </div>
        </div>
        <!-- Status Data -->
        <div
          v-for="(item, index) in table"
          :key="'status-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            v-if="item.status == 'Completed'"
            class="px-3 pt-1 mix-blend-multiply bg-emerald-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-emerald-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ item.status }}
            </div>
          </div>
          <div
            v-if="item.status == 'In Progress'"
            class="px-3 pt-1 mix-blend-multiply bg-purple-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-purple-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ item.status }}
            </div>
          </div>
          <div
            v-if="item.status == 'Active'"
            class="px-3 pt-1 mix-blend-multiply bg-blue-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-blue-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ item.status }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-auto flex-col justify-start items-start inline-flex">
        <!-- Actions Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Actions
            </div>
          </div>
        </div>
        <!-- Actions Data -->
        <div
          v-for="(item, index) in table"
          :key="'actions-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-center items-center inline-flex"
        >
          <DropdownMenu :item="item" />
        </div>
      </div>
    </div>
    <div v-else class="w-full bg-white rounded-bl-[20px] rounded-br-[20px] p-4">
      <div
        v-for="(item, index) in table"
        :key="'card-' + index"
        class="bg-slate-50 p-4 mb-4 rounded-lg shadow-md flex flex-col gap-4"
      >
        <!-- <div class="flex items-center gap-4">
         
          <div>
            <h3 class="text-lg font-semibold text-neutral-900">
              {{ item.id_project }}
            </h3>
            <p class="text-sm text-slate-600">  Client: {{
                item.Client
                  ? item.Client.name + " " + item.Client.last_name
                  : ""
              }}</p>
          </div>
        </div> -->
        
        <div class="flex justify-end items-center">
        
        <DropdownMenu :item="item" />
      </div>

        <div class="text-sm text-slate-600">
          <p>
            <strong> {{ item.name }}</strong>
          </p>
          <p>
            <strong>Start Date:</strong>
            {{ helpers.formatDateToMMDDYYYY(item.start_date) }}
          </p>
          <p>
            <strong>Due Date:</strong>
            {{ helpers.formatDateToMMDDYYYY(item.due_date) }}
          </p>
          <p>
            <strong>Address:</strong> {{ item.street }}
            {{ item.apartment_unit }}, {{ item.city }}, {{ item.state }}
            {{ item.zipcode }}
          </p>
        </div>

      </div>
    </div>
    <PPagination />
  </div>
</template>
      <script>
import PPagination from "../Projects/ProjectPagination.vue";
import DropdownMenu from "../Projects/ProjectsDropDown.vue";
import LoaderSpinner from "../CircularLoader.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import helpers from "../../helpers";

export default {
  components: {
    PPagination,
    DropdownMenu,
    LoaderSpinner,
  },

  setup() {
    const store = useStore();
    const table = computed(() => store.getters.table);
    const flagLoading = computed(() => store.getters.loading);
    const isMobileView = computed(() => store.getters.isMobileView);
    return {
      table,
      flagLoading,
      helpers,
      isMobileView,
    };
  },
};
</script>
import api from "../../api";
import { auth } from "../../firebase/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import router from "@/router";
export default {
  async setUserAct({ commit, dispatch }, data) {
    const { email, password } = data;
    try {
      dispatch("showLoader");

      await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;

          user
            .getIdToken(true)
            .then(async (idToken) => {
              let userAuth = await api.post("/users/auth-with-token", {
                token: idToken,
              });
              if (userAuth.ok) {
                commit("setUserMut", {
                  email: user.email,
                  uid: user.uid,
                  type: userAuth.type,
                  photo: userAuth.photo,
                });
                dispatch("hideLoader");
                router.push({ name: "dashboard" });
              } else {
                dispatch("showSnackbar", "Something went wrong");
              }
            })
            .catch(function (error) {
              console.log("🚀 ~ Obtener token de acceso ~ error:", error);
              return false;
            });
        })
        .catch((error) => {
          if (error.code === "auth/invalid-credential") {
            dispatch("showSnackbar", "Invalid credentials");
            dispatch("hideLoader");
            console.log("Credenciales inválidas");
          }
          return false;
        });
    } catch (error) {
      dispatch("hideLoader");
      dispatch("showSnackbar", "Internal Error");
      console.error("Error en la solicitud:", error);
      return false;
    }
  },

  async logOut({ commit }) {
    try {
      commit("clearUserMut");
      // document.cookie = "theCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      await auth.signOut();
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  },

  async forgotPassword(_, data) {
    try {
      const response = await api.post(
        "/new-kambuchi/users/forgot-password",
        data
      );
      console.log("🚀 ~ forgotUser ~ response:", response);
    } catch (error) {
      console.error("Error in forgotUser action:", error);
      throw error;
    }
  },

  async verifyOtp(_, data) {
    try {
      const response = await api.post("/new-kambuchi/users/verify-otp", data);
      return response;
    } catch (error) {
      console.error("Error in forgotUser action:", error);
      throw error;
    }
  },

  async setNewPassword(_, data) {
    try {
      const response = await api.post("/new-kambuchi/users/new-password", data);
      return response;
    } catch (error) {
      console.error("Error in forgotUser action:", error);
      throw error;
    }
  },

  async generateRegistrationOtp(_, data) {
    try {
      const response = await api.post(
        "/new-kambuchi/users/generate-register-otp",
        data
      );
      return response;
    } catch (error) {
      console.error("Error in generateRegistrationOtp action:", error);
      throw error;
    }
  },

  async checkRegistrationOtp(_, data) {
    try {
      const response = await api.post(
        "/new-kambuchi/users/check-register-otp",
        data
      );
      return response;
    } catch (error) {
      console.error("Error in checkRegistrationOtp action:", error);
      throw error;
    }
  },

  async confirmResourceInfo({ commit }, data) {
    try {
      const response = await api.put(
        `/new-kambuchi/users/confirm-resource-info`,
        data
      );

      if (response.ok) {
        return response;
      } else {
        if (response.error.includes("phone_number_UNIQUE")) {
          commit("setSnackbar", {
            text: "Phone number is already in use, please use another.",
            enabled: true,
          });
          return response;
        } else if (response.error.includes("email_UNIQUE")) {
          commit("setSnackbar", {
            text: "Email is already in use, please use another.",
            enabled: true,
          });
          return response;
        } else {
          commit("setSnackbar", {
            text: "Unexpected error while editing resource",
            enabled: true,
          });
          return response;
        }
      }
    } catch (error) {
      console.error("Error in confirmResourceInfo action:", error);
      throw error;
    }
  },

  async deleteUser({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/users/delete-user`, data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while deleting user",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in deleteUser action:", error);
      throw error;
    }
  },

  async fetchEmployees() {
    try {
      const response = await api.get("/new-kambuchi/users/employees");
      if (response.ok) {
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  
  async fetchContractors() {
    try {
      const response = await api.get("/new-kambuchi/users/contractors");
      if (response.ok) {
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};

<template>
  <div>
    <!-- Diseño para Desktop -->
    <div
      v-if="!isMobileView"
      class="w-full h-[82px] px-4 pt-6 pb-4 justify-between items-center inline-flex"
    >
      <div
        class="w-[554px] text-neutral-900 text-lg text-left font-semibold font-['Public Sans'] leading-7"
      >
        Client Management
      </div>
      <div class="ml-auto flex justify-start items-center gap-3">
        <!-- Search Input -->
        <div class="relative">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchQuery"
            @input="fetchFiltered"
            class="px-20 py-[9px] bg-white rounded-lg border border-slate-200 pl-10 pr-2"
          />
          <img
            src="/icons/projects/search.svg"
            class="absolute left-3 top-3 w-5 h-5 pointer-events-none"
          />
        </div>
        <!-- Add Client Button -->
        <button
          @click="newClientView"
          class="px-4 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
        >
          <img
            src="/icons/projects/create-new-project.svg"
            class="w-5 h-5 relative"
          />
          <div class="text-white text-sm font-semibold">Add Client</div>
        </button>
      </div>
    </div>

    <!-- Diseño para Mobile -->
    <div
      v-else
      class="w-full h-auto px-4 pt-4 pb-2 flex flex-col gap-4 bg-white"
    >
      <!-- Header -->
      <div
        class="text-neutral-900 text-base font-semibold font-['Public Sans'] leading-6"
      >
        Client Management
      </div>
      <!-- Search Input -->
      <div class="relative">
        <input
          type="text"
          placeholder=""
          v-model="searchQuery"
          @input="fetchFiltered"
          class="w-full px-4 py-[10px] bg-white rounded-md border border-slate-200"
        />
        <img
          src="/icons/projects/search.svg"
          class="absolute right-3 top-3.5 w-5 h-5 pointer-events-none"
        />
      </div>
      <!-- Add Client Button -->
      <button
        @click="newClientView"
        class="w-full py-3 bg-blue_primary rounded-lg shadow flex justify-center items-center gap-2"
      >
        <img src="/icons/projects/create-new-project.svg" class="w-5 h-5" />
        <div class="text-white text-sm font-semibold">Add Client</div>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import helpers from "../../helpers";

export default {
  emits: ["tab-selected-user-management"],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const selectedTab = ref("Employees");
    const searchQuery = ref("");

    const selectTab = (tab) => {
      selectedTab.value = tab;
      emit("tab-selected-user-management", tab);
    };

    const fetchFiltered = helpers.createDebouncedFetch(store, "clients", "");

    const newClientView = () => {
      router.push({ name: "create-new-client" });
    };

    const isMobileView = computed(() => store.getters.isMobileView);

    return {
      selectedTab,
      selectTab,
      searchQuery,
      fetchFiltered,
      newClientView,
      isMobileView,
    };
  },
};
</script>

<style scoped>
/* Aquí puedes poner estilos específicos si los necesitas */
</style>


<template>
  <div>
    <div
      v-if="flagShow && !isMobileView"
      class="w-full h-[88px] px-4 pt-6 pb-4 justify-between items-center inline-flex"
    >
      <div
        class="p-1 bg-white rounded-lg border border-slate-100 justify-start items-start flex"
      >
        <button
          @click="selectType('Active')"
          :class="
            selectedType == 'Active'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedType == 'Active'
                ? 'text-blue_primary text-sm font-semibold leading-snug'
                : 'text-gray-500 text-sm font-normal leading-snug'
            "
          >
            Active
          </div>
        </button>
        <button
          @click="selectType('Complete')"
          :class="
            selectedType == 'Complete'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedType == 'Complete'
                ? 'text-emerald-700 text-sm font-semibold leading-snug'
                : 'text-gray-500 text-sm font-normal leading-snug'
            "
          >
            Complete
          </div>
        </button>
      </div>
      <div class="justify-start items-center gap-3 flex">
        <div class="relative">
          <input
            type="text"
            placeholder="Search tasks..."
            v-model="searchQuery"
            @input="fetchFiltered"
            class="px-20 py-[9px] bg-white rounded-lg border border-slate-200 pl-10 pr-2"
          />
          <img
            src="/icons/projects/search.svg"
            class="absolute left-3 top-3 w-5 h-5 pointer-events-none"
            style="pointer-events: none"
          />
        </div>
        <select
          class="w-36 h-12 px-3.5 py-2.5 bg-white rounded-lg shadow border border-slate-200 justify-start items-center gap-2 flex"
        >
          <div
            class="grow shrink basis-0 h-[26px] justify-start items-center gap-2 flex"
          >
            <div
              class="grow shrink basis-0 text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
            >
              <option value="" disabled selected>Assigned</option>
            </div>
          </div>
          <div class="w-4 h-4 relative"></div>
        </select>
        <button
          @click="toggleModal()"
          class="px-4 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
        >
          <img
            src="/icons/projects/create-new-project.svg"
            class="w-5 h-5 relative"
          />
          <div
            class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Create New Task
          </div>
        </button>
        <button
          v-if="user_type == 1"
          class="px-4 py-[9px] bg-blue_primary rounded-lg shadow flex items-center gap-2"
          @click="toggleModalAssign()"
        >
          <img
            src="/icons/projects/create-new-project.svg"
            class="w-5 h-5 relative"
          />
          <div
            class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Assign Resources
          </div>
        </button>
      </div>
    </div>
    <div v-if="flagShow && isMobileView" class="w-full p-4 flex flex-col gap-4">
      <div class="flex gap-2">
        <button
          @click="selectType('Active')"
          :class="[
            'flex-1 py-2 rounded-lg text-sm font-semibold',
            selectedType === 'Active'
              ? 'bg-blue_primary text-white'
              : 'bg-slate-100 text-gray-500',
          ]"
        >
          Active
        </button>
        <button
          @click="selectType('Complete')"
          :class="[
            'flex-1 py-2 rounded-lg text-sm font-semibold',
            selectedType === 'Complete'
              ? 'bg-emerald-700 text-white'
              : 'bg-slate-100 text-gray-500',
          ]"
        >
          Complete
        </button>
      </div>
      <button
        @click="toggleCollapse"
        class="p-2 bg-slate-100 rounded-md "
      >
        <img
          :src="isCollapsed ? '/icons/global-timer/expand.svg' : '/icons/global-timer/collapse.svg'"
          class="w-5 h-5 ml-auto"
          alt="Toggle Collapse"
        />
      </button>

      <div v-show="!isCollapsed"  class="flex flex-col gap-2">
        <div class="relative">
          <input
            type="text"
            placeholder="Search tasks..."
            v-model="searchQuery"
            @input="fetchFiltered"
            class="w-full py-2 pl-10 pr-2 bg-white rounded-lg border border-slate-200"
          />
          <img
            src="/icons/projects/search.svg"
            class="absolute left-3 top-2.5 w-5 h-5 pointer-events-none"
          />
        </div>

        <select
          class="w-full py-2 px-3 bg-white rounded-lg border border-slate-200"
        >
          <option value="" disabled selected>Assigned</option>
        </select>

        <button
          @click="toggleModal()"
          class="w-full py-2 bg-blue_primary rounded-lg text-white font-semibold flex items-center justify-center gap-2"
        >
          <img src="/icons/projects/create-new-project.svg" class="w-5 h-5" />
          Create New Task
        </button>

        <button
          v-if="user_type == 1"
          @click="toggleModalAssign()"
          class="w-full py-2 bg-blue_primary rounded-lg text-white font-semibold flex items-center justify-center gap-2"
        >
          <img src="/icons/projects/create-new-project.svg" class="w-5 h-5" />
          Assign Resources
        </button>
      </div>
    </div>
    <CreateNewTask
      v-if="showModalChangeStatus"
      :isOpen="showModalChangeStatus"
      :projectId="id_project"
      :project_start="details.start_date"
      :project_end="details.due_date"
      @close="showModalChangeStatus = false"
    />
    <AssignResources
      v-if="showAssignResources"
      :isOpen="showAssignResources"
      :projectId="id_project"
      @close="showAssignResources = false"
    />
  </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import CreateNewTask from "./TaskCreateNewTask.vue";
import AssignResources from "../AssingResources.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import helpers from "../../../helpers";

export default {
  components: {
    CreateNewTask,
    AssignResources,
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const id_project = route.params.id;
    const showModalChangeStatus = ref(false);
    const showAssignResources = ref(false);
    const searchQuery = ref("");
    const user_type = computed(() => store.state.user.type);
    const flagShow = ref(false);
    const isMobileView = computed(() => store.getters.isMobileView);
    const selectedType = computed(() => store.getters.selectedType);
    const details = computed(() => store.getters.detailsData);
    const isCollapsed = ref(true);
    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    function toggleModal() {
      showModalChangeStatus.value = !showModalChangeStatus.value;
    }
    function toggleModalAssign() {
      showAssignResources.value = !showAssignResources.value;
    }

    const selectType = async (type) => {
      const types =
        type == "Active"
          ? ["In progress", "Not Started", "Missed"]
          : ["Complete"];
      await store.commit("setSelectedType", type);
      await store.dispatch("fetchTable", {
        page: 1,
        limit: 10,
        type: "tasks",
        searchType: types,
        id_project: id_project,
      });

      emit("type-selected-task", type);
    };

    const fetchFiltered = helpers.createDebouncedFetch(
      store,
      "tasks",
      selectedType,
      id_project
    );

    onMounted(async () => {
      try {
        flagShow.value = false;
        if (selectedType.value == null) {
          await store.commit("setSelectedType", "Active");
        }
        flagShow.value = true;
      } catch (error) {
        console.error("Error setting type:", error);
      }
    });

    return {
      toggleModal,
      showModalChangeStatus,
      showAssignResources,
      toggleModalAssign,
      id_project,
      selectType,
      flagShow,
      selectedType,
      fetchFiltered,
      searchQuery,
      user_type,
      isMobileView,
      isCollapsed,
      toggleCollapse,
      details
    };
  },
};
</script>
import store from "@/store";
import authMid from "./authMid";
import { createRouter, createWebHistory } from "vue-router";

import LoginUser from "@/views/LoginUser.vue";
import RegisterOwner from "@/components/LoginComponents/RegisterOwner.vue";
import ForgotPassword from "@/components/LoginComponents/ForgotPassword.vue";
import RegisterResource from "@/components/LoginComponents/RegisterResource.vue";


import CreateProject from "@/components/Projects/CreateNewProject.vue";
import CreateTimesheet from "@/components/Projects/Timesheets/CreateNewTimesheet.vue";
import CreateExpense from "@/components/Projects/Expenses/CreateNewExpense.vue";
import CreateInvoice from "@/components/Projects/Invoices/CreateNewInvoice.vue";

import CreateClient from "@/components/ClientManagement/CreateNewClient.vue";
import EditClient from "@/components/ClientManagement/ClientEdit.vue";

import CreateResource from "@/components/UserManagement/CreateNewUser.vue";
import EditResource from "@/components/UserManagement/ResourceEdit.vue";

import Tasktest from "@/views/ProjectDetailsView.vue";
import Dashboard from "@/views/MainDashboard.vue";
import Expenses from "@/views/ExpensesView.vue";
import UserManagement from "@/views/UserManagementView.vue";
import ClientManagement from "@/views/ClientManagementView.vue";
import ClientDescription from "@/views/ClientDescriptionView.vue";
import Projects from "@/views/ProjectsView.vue";
import Timesheets from "@/views/TimesheetsView.vue";
import Invoices from "@/views/InvoicesView.vue";
import Bills from "@/views/BillsView.vue";

import AccountSettings from "@/views/AccountSettingsView.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/register-v2",
    name: "register-owner",
    component: RegisterOwner,
    meta: { middleware: authMid },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/register-resource",
    name: "register-resource",
    component: RegisterResource,
    meta: { middleware: authMid },
  },
  {
    path: "/login",
    name: "login",
    component: LoginUser,
    meta: { middleware: authMid },
  },

  {
    path: "/create-new-project",
    name: "create-new-project",
    component: CreateProject,
    meta: { middleware: authMid },
  },
  {
    path: "/create-new-timesheet",
    name: "create-new-timesheet",
    component: CreateTimesheet,
    meta: { middleware: authMid },
  },
  {
    path: "/create-new-expense",
    name: "create-new-expense",
    component: CreateExpense,
    meta: { middleware: authMid },
  },
  {
    path: "/create-new-invoice",
    name: "create-new-invoice",
    component: CreateInvoice,
    meta: { middleware: authMid },
  },
  {
    path: "/create-new-client",
    name: "create-new-client",
    component: CreateClient,
    meta: { middleware: authMid },
  },
  {
    path: "/edit-client/:id",
    name: "edit-client",
    component: EditClient,
    meta: { middleware: authMid },
  },

  {
    path: "/create-new-resource",
    name: "create-new-resource",
    component: CreateResource,
    meta: { middleware: authMid },
  },
  {
    path: "/edit-resource/:id",
    name: "edit-resource",
    component: EditResource,
    meta: { middleware: authMid },
  },

  {
    path: "/project-details/:id",
    name: "project-details",
    component: Tasktest,
    meta: { middleware: authMid },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { middleware: authMid },
  },
  {
    path: "/expenses",
    name: "expenses",
    component: Expenses,
    meta: { middleware: authMid },
  },
  {
    path: "/resource-management",
    name: "resource-management",
    component: UserManagement,
    meta: { middleware: authMid },
  },
  {
    path: "/client-management",
    name: "client-management",
    component: ClientManagement,
    meta: { middleware: authMid },
  },
  {
    path: "/client-desc/:id",
    name: "client-desc",
    component: ClientDescription,
    meta: { middleware: authMid },
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects,
    meta: { middleware: authMid },
  },
  {
    path: "/timesheets",
    name: "timesheets",
    component: Timesheets,
    meta: { middleware: authMid },
  },
  {
    path: "/invoices",
    name: "invoices",
    component: Invoices,
    meta: { middleware: authMid },
  },
  {
    path: "/bills",
    name: "bills",
    component: Bills,
    meta: { middleware: authMid },
  },
  {
    path: "/account-settings",
    name: "account-settings",
    component: AccountSettings,
    meta: { middleware: authMid },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Kamvu";
  next();
});
router.beforeEach(async (to, from, next) => {
  if (!store.state.appLoaded) {
    await new Promise((resolve) => {
      const checkLoaded = setInterval(() => {
        if (store.state.appLoaded) {
          clearInterval(checkLoaded);
          resolve();
        }
      }, 50);
    });
  }

  if (to.meta.middleware) {
    to.meta.middleware(to, from, next);
  } else {
    next();
  }
});

export default router;

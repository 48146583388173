import api from "../../api";

export default {
  async fetchTable(
    { commit, state },
    {
      page = 1,
      limit = 10,
      type,
      search = "",
      searchType = "",
      id_project,
      id_client,
    }
  ) {
    const user_type = state.user.type;
    const user_id = state.user.uid;
    commit("setLoading", true);

    try {
      const params = { page, limit, search, searchType };

      if (user_type !== 1) {
        params.id_user = user_id;
      }
      if (id_project) {
        params.id_project = id_project;
      }
      if (id_client) {
        params.id_client = id_client;
      }
      const response = await api.get(`/new-kambuchi/${type}`, params);

      commit("setClients", response.data);
      commit("setPagination", {
        totalItems: response.totalItems,
        totalPages: response.totalPages,
        currentPage: response.currentPage,
      });
      commit("setLoading", false);
    } catch (error) {
      console.error("Error fetching data:", error);
      commit("setLoading", false);
    }
  },

  updateSelectedTab({ commit }, tab) {
    commit("setSelectedTab", tab);
  },
};

<template>
  <LoaderSpinner v-if="flagLoading" />
  <div
    v-else
    class="sm:w-[70%] h-auto p-4 bg-white rounded-[20px] shadow flex-col justify-start items-start gap-5 inline-flex"
  >
    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Create New Resource
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- Resource Name -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
        >
          Resource Name
        </div>
        <input
          v-model="resourceName"
          type="text"
          placeholder="Name"
          :class="{
            'w-full self-stretch px-3  py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !resourceName,
            'w-full self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              resourceName,
          }"
        />
      </div>

      <!-- Resource Last Name -->
      <div class="w-full flex-col text-left justify-start items-start gap-1.5">
        <div
          class="w-full text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Resource Last Name
        </div>
        <input
          v-model="resourceLastName"
          type="text"
          placeholder="Last name"
          :class="{
            'w-full self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !resourceLastName,
            'w-full self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              resourceLastName,
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- Resource Type Select -->
      <div class="w-1/2 flex-col justify-start items-start gap-1.5 flex">
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Resource Type
        </div>
        <select
          v-model="resourceType"
          :class="{
            'self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !resourceType,
            'self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              resourceType,
          }"
        >
          <option value="" disabled>Select an option</option>
          <option value="Employee">Employee</option>
          <option value="Contractor">Contractor</option>
        </select>
      </div>

      <!-- Rate Input (shown only if Contractor is selected) -->
      <div
        v-if="resourceType == 'Contractor'"
        class="w-1/2 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Rate
        </div>
        <input
          v-model="rate"
          type="number"
          placeholder="Resource rate"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !rate,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              rate,
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Email
        </div>
        <input
          v-model="resourceEmail"
          type="email"
          placeholder="mail@example.com"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !isEmailValid(resourceEmail),
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              isEmailValid(resourceEmail),
          }"
        />
      </div>

      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Phone Number
        </div>
        <input
          v-model="phoneNumberDisplay"
          type="tel"
          placeholder="phone number [XXX-XXX-XXXX]"
          @input="updatePhoneNumber"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !isPhoneNumberValid(phoneNumber),
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              isPhoneNumberValid(phoneNumber),
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- Street -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Street
        </div>
        <input
          v-model="street"
          type="text"
          placeholder="Street address"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !street,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              street,
          }"
        />
      </div>

      <!-- Apartment/Unit -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Apartment/Unit
        </div>
        <input
          v-model="apartmentUnit"
          type="text"
          placeholder="Apartment or unit number"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !apartmentUnit,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              apartmentUnit,
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- City -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          City
        </div>
        <input
          v-model="city"
          type="text"
          placeholder="City"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !city,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              city,
          }"
        />
      </div>

      <!-- State -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          State
        </div>
        <input
          v-model="state"
          type="text"
          placeholder="State"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !state,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              state,
          }"
        />
      </div>

      <!-- Zipcode -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Zip Code
        </div>
        <input
          v-model="zipcode"
          type="text"
          placeholder="Zip code"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !zipcode,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              zipcode,
          }"
        />
      </div>
    </div>
    <!-- File Upload -->
    <div class="w-full flex-col justify-start items-start gap-1.5">
      <div
        class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Profile Picture
      </div>
      <input
        type="file"
        @change="handleFileUpload"
        accept="image/*"
        :class="{
          'w-full px-3 py-2  bg-white rounded-lg shadow border-2 border-red-400 border-solid':
            !selectedFile,
          'w-full px-3 py-2  bg-white rounded-lg shadow border-2 border-green-400 border-solid':
            selectedFile,
        }"
      />
    </div>
    <div class="w-full justify-end items-center gap-3 inline-flex">
      <button
        @click="handleCancel()"
        class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
      >
        <div
          class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Cancel
        </div>
      </button>
      <button
        @click="handleCreateClient"
        :disabled="!isFormValid"
        :class="
          !isFormValid
            ? 'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex'
            : 'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex'
        "
      >
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Create Resource
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LoaderSpinner from "../CircularLoader.vue";

export default {
  components: {
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const resourceName = ref("");
    const resourceLastName = ref("");
    const resourceType = ref("");
    const resourceEmail = ref("");
    const phoneNumber = ref("");
    const phoneNumberDisplay = ref("");
    const street = ref("");
    const apartmentUnit = ref("");
    const city = ref("");
    const state = ref("");
    const zipcode = ref("");
    const flagLoading = ref(false);
    const selectedFile = ref(null);
    const rate = ref("");

    function handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        selectedFile.value = file;
      }
    }

    function isEmailValid(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    function isPhoneNumberValid(phone) {
      const re = /^\d{10}$/;
      return re.test(phone);
    }

    const isFormValid = computed(() => {
      return (
        resourceName.value &&
        resourceLastName.value &&
        resourceType.value &&
        resourceEmail.value &&
        isEmailValid(resourceEmail.value) &&
        phoneNumber.value &&
        isPhoneNumberValid(phoneNumber.value) &&
        street.value &&
        city.value &&
        state.value &&
        zipcode.value &&
        (resourceType.value !== "Contractor" || rate.value)
      );
    });

    async function handleCreateClient() {
      if (!isFormValid.value) {
        return;
      }

      try {
        flagLoading.value = true;

        const formData = new FormData();
        formData.append("name", resourceName.value);
        formData.append("last_name", resourceLastName.value);
        formData.append(
          "user_type_id",
          resourceType.value === "Employee" ? 2 : 3
        );
        formData.append("email", resourceEmail.value);
        formData.append("phone_number", phoneNumber.value);
        if (selectedFile.value) {
          formData.append("resource_profile_picture", selectedFile.value);
        }
        formData.append("street", street.value);
        formData.append("apartment_unit", apartmentUnit.value);
        formData.append("city", city.value);
        formData.append("state", state.value);
        formData.append("zipcode", zipcode.value);
        if (rate.value) {
          formData.append("rate", rate.value);
        }

        const response = await fetch("new-kambuchi/users/add-user", {
          method: "POST",
          body: formData,
        });

        const newUser = await response.json();

        if (newUser.ok) {
          resourceName.value = "";
          resourceLastName.value = "";
          resourceEmail.value = "";
          phoneNumber.value = "";
          street.value = "";
          apartmentUnit.value = "";
          city.value = "";
          state.value = "";
          zipcode.value = "";
          selectedFile.value = null;
          rate.value = "";

          router.push({ name: "resource-management" });
        } else {
          await store.commit("setSnackbar", {
            text: "Error while adding resource.",
            enabled: true,
          });
        }
      } catch (error) {
        flagLoading.value = false;
        console.error("Error creating resource:", error);
      }
    }

    function handleCancel() {
      resourceName.value = "";
      resourceLastName.value = "";
      resourceEmail.value = "";
      phoneNumber.value = "";
      street.value = "";
      apartmentUnit.value = "";
      city.value = "";
      state.value = "";
      zipcode.value = "";
      rate.value = "";
      router.push({ name: "resource-management" });
    }

    function updatePhoneNumber(event) {
      const input = event.target.value.replace(/\D/g, "");
      phoneNumber.value = input;
      if (input.length <= 3) {
        phoneNumberDisplay.value = input;
      } else if (input.length <= 6) {
        phoneNumberDisplay.value = `${input.slice(0, 3)}-${input.slice(3)}`;
      } else {
        phoneNumberDisplay.value = `${input.slice(0, 3)}-${input.slice(
          3,
          6
        )}-${input.slice(6)}`;
      }
    }

    return {
      resourceName,
      resourceLastName,
      resourceType,
      resourceEmail,
      phoneNumber,
      phoneNumberDisplay,
      street,
      apartmentUnit,
      city,
      state,
      zipcode,
      isEmailValid,
      isPhoneNumberValid,
      isFormValid,
      handleCreateClient,
      updatePhoneNumber,
      flagLoading,
      handleCancel,
      handleFileUpload,
      selectedFile,
      rate,
    };
  },
};
</script>

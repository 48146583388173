<template>
  <div>
    <button
      v-if="!isSidebarOpen"
      class="fixed top-4 left-4 z-50 bg-blue_primary text-white p-2 rounded-md shadow-md"
      @click="toggleSidebar"
    >
      ☰
    </button>

    <div
      :class="[
        'fixed top-0 left-0 h-full w-64 bg-blue_primary text-white flex flex-col transition-transform duration-300 z-40',
        { '-translate-x-full': !isSidebarOpen },
      ]"
    >
      <div
        class="h-16 border-b border-white/50 flex items-center justify-center"
        @click="navigateHome"
      >
        <img class="h-10 cursor-pointer" src="/kamvu/logox4.png" alt="Logo" />
      </div>

      <div class="flex-1 overflow-y-auto p-4">
        <div v-for="item in filteredNavItems" :key="item.text" class="mb-2">
          <div
            :class="['nav-item', { selected: selectedNavItem === item.text }]"
            @click="handleClick(item)"
          >
            <div class="flex items-center gap-3 text-start">
              <img :src="item.icon" class="h-6 w-6" alt="Icon" />
              <span>{{ item.text }}</span>
            </div>
          </div>

          <div
            v-if="item.subItems && expandedNavItem === item.text"
            class="ml-8"
          >
            <div
              v-for="subItem in item.subItems"
              :key="subItem.text"
              class="nav-item"
              :class="{ selected: selectedNavItem === subItem.text }"
              @click="handleSubItemClick(subItem)"
            >
              <span>{{ subItem.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isSidebarOpen"
      class="fixed inset-0 bg-black/50 z-30"
      @click="toggleSidebar"
    ></div>
  </div>
</template>
  
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const isSidebarOpen = ref(false);
    const selectedNavItem = ref("");
    const expandedNavItem = ref("");

    const userRole = computed(() => store.state.user?.type || null);

    const navItems = [
      { text: "Dashboard", icon: "/icons/side-bar/dashboard.svg" },
      {
        text: "Client Management",
        icon: "/icons/side-bar/client-management.svg",
      },
      {
        text: "Resource Management",
        icon: "/icons/side-bar/user-management.svg",
      },
      {
        text: "Project Management",
        icon: "/icons/side-bar/projects.svg",
        subItems: [
          { text: "Projects", icon: "/icons/side-bar/project-management.svg" },
          { text: "Timesheets", icon: "/icons/side-bar/timesheets.svg" },
          { text: "Invoices", icon: "/icons/side-bar/invoices.svg" },
          { text: "Expenses", icon: "/icons/side-bar/expenses.svg" },
          { text: "Bills", icon: "/icons/side-bar/expenses.svg" },
        ],
      },
      { text: "Reports", icon: "/icons/side-bar/reports.svg" },
      { text: "Account Settings", icon: "/icons/side-bar/settings.svg" },
      // { text: "Log Out", icon: "/icons/side-bar/logout.svg" },
    ];

    const filteredNavItems = computed(() => {
      const items = JSON.parse(JSON.stringify(navItems));

      if (userRole.value && userRole.value == 1) {
        return items;
      }

      const filteredItems = items.filter(
        (item) =>
          item.text !== "Resource Management" &&
          item.text !== "Client Management"
      );

      filteredItems.forEach((item) => {
        if (item.text === "Project Management" && item.subItems) {
          item.subItems = item.subItems.filter((subItem) => {
            if (userRole.value === 2) {
              return subItem.text !== "Bills" && subItem.text !== "Invoices";
            } else if (userRole.value === 3) {
              return (
                subItem.text !== "Timesheets" && subItem.text !== "Invoices"
              );
            }
            return subItem.text !== "Invoices";
          });
        }
      });

      return filteredItems;
    });

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };

    const navigateHome = () => {
      router.push({ name: "dashboard" });
      isSidebarOpen.value = false;
    };

    const handleClick = (item) => {
      selectedNavItem.value = item.text;
      if (item.subItems) {
        expandedNavItem.value =
          expandedNavItem.value === item.text ? "" : item.text;
      } else {
        expandedNavItem.value = "";
        router.push({ name: item.text.toLowerCase().replace(/\s+/g, "-") });
        isSidebarOpen.value = false;
      }
    };

    const handleSubItemClick = (subItem) => {
      selectedNavItem.value = subItem.text;
      router.push({ name: subItem.text.toLowerCase().replace(/\s+/g, "-") });
      isSidebarOpen.value = false;
    };

    return {
      isSidebarOpen,
      toggleSidebar,
      navigateHome,
      filteredNavItems,
      selectedNavItem,
      expandedNavItem,
      handleClick,
      handleSubItemClick,
    };
  },
};
</script>
<style scoped>
.nav-item {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-item:hover,
.nav-item.selected {
  background-color: rgba(255, 255, 255, 0.2);
}

.selected {
  font-weight: bold;
}

.selected-icon {
  filter: brightness(0) saturate(100%) invert(100%);
}
</style>
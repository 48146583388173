import api from "../../api";

export default {
  async addService({ commit }, data) {
    try {
      const response = await api.post(
        "new-kambuchi/account-settings/services-catalog/add-service",
        data
      );

      if (response.ok) {
        commit("setSnackbar", {
          text: "Service added successfully",
          enabled: true,
        });
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding service",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addService action:", error);
      throw error;
    }
  },

  async fetchAllServices({ commit }, { query = "", page = 0, limit = 10 }) {
    try {
      const params = { searchQuery: query, page, limit };
      const response = await api.get(
        "/new-kambuchi/account-settings/services-catalog/get-services",
        params
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching services",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchServicesForInvoice({ commit }, { searchQuery = "" }) {
    try {
      const params = { searchQuery };
      const response = await api.get(
        "/new-kambuchi/account-settings/services-catalog/get-all-services",
        params
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching services",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async editService({ commit }, data) {
    try {
      const response = await api.put(
        `/new-kambuchi/account-settings/services-catalog/edit-service`,
        data
      );

      if (response.ok) {
        commit("setSnackbar", {
          text: response.message,
          enabled: true,
        });
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while editing service",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in editService action:", error);
      throw error;
    }
  },

  async deleteService({ commit }, data) {
    try {
      const response = await api.delete(
        `/new-kambuchi/account-settings/services-catalog/delete-service`,
        data
      );

      if (response.ok) {
        commit("setSnackbar", {
          text: response.message,
          enabled: true,
        });
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while deleting service",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in deleteService action:", error);
      throw error;
    }
  },
};

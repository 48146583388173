<template>
  <LoaderSpinner v-if="flagLoading" />
  <div class="w-full min-h-screen relative bg-[#b7cef2] overflow-auto" v-else>
    <div class="absolute inset-0 flex flex-col items-center justify-start">
      <div
        class="absolute w-[300px] h-[310px] md:w-[569px] md:h-[585px] bg-[#1762d4] rounded-full blur-[300px] md:blur-[400px]"
        style="z-index: 1"
      ></div>
      <div
        class="absolute w-[200px] h-[210px] md:w-[351px] md:h-[361px] bg-[#1762d4] rounded-full blur-[200px] md:blur-[300px]"
        style="z-index: 2"
      ></div>
      <img
        class="w-[250px] h-auto md:w-[500px] md:h-[130px] mt-[20px] md:mt-[50px]"
        src="/kamvu/logo2x4.png"
        style="z-index: 3"
      />
      <div
        class="w-[90%] max-w-[800px] mt-8 md:mt-10 bg-white rounded-xl shadow-lg p-5 md:p-10 flex flex-col gap-5"
        style="z-index: 4"
      >
        <div
          class="self-stretch justify-center items-center gap-[105px] inline-flex"
        >
          <div
            class="text-neutral-900 text-xl font-semibold font-['Public Sans'] leading-7"
          >
            Register Account Owner
          </div>
        </div>

        <div class="w-full flex flex-col gap-5">
          <!-- First Name -->
          <div class="w-full flex-col justify-start items-start gap-1.5">
            <div
              class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
            >
              First Name
            </div>
            <input
              v-model="firstName"
              type="text"
              placeholder="First Name"
              :class="{
                'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !firstName,
                'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  firstName,
              }"
            />
          </div>

          <!-- Last Name -->
          <div class="w-full flex-col justify-start items-start gap-1.5">
            <div
              class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
            >
              Last Name
            </div>
            <input
              v-model="lastName"
              type="text"
              placeholder="Last Name"
              :class="{
                'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !lastName,
                'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  lastName,
              }"
            />
          </div>

          <div class="w-full flex flex-col md:flex-row gap-5">
            <!-- Email -->
            <div class="w-full flex-col justify-start items-start gap-1.5">
              <div
                class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
              >
                Email
              </div>
              <input
                v-model="email"
                type="email"
                placeholder="mail@example.com"
                :class="{
                  'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                    !isEmailValid(email),
                  'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                    isEmailValid(email),
                }"
              />
            </div>

            <!-- Phone Number -->
            <div class="w-full flex-col justify-start items-start gap-1.5">
              <div
                class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
              >
                Phone Number
              </div>
              <input
                v-model="phoneNumberDisplay"
                type="tel"
                placeholder="phone number [XXX-XXX-XXXX]"
                @input="updatePhoneNumber"
                :class="{
                  'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                    !isPhoneNumberValid(phoneNumber),
                  'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                    isPhoneNumberValid(phoneNumber),
                }"
              />
            </div>
          </div>

          <!-- Passwords -->
          <div class="w-full flex flex-col md:flex-row gap-5">
            <!-- Password -->
            <div class="w-full flex-col justify-start items-start gap-1.5">
              <div
                class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
              >
                Password
              </div>
              <div class="relative w-full">
                <input
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="********"
                  :class="{
                    'w-full px-3 py-2 pr-10 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                      !isPasswordValid(password),
                    'w-full px-3 py-2 pr-10 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                      isPasswordValid(password),
                  }"
                />

                <img
                  @click="toggleShowPassword"
                  class="w-5 h-5 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  src="/icons/login-register/eye.svg"
                  alt="Eye Icon"
                />
              </div>

              <div class="text-xs text-red-500 mt-2 text-left">
                <ul>
                  <li :class="{ 'text-green-500': isPasswordLongEnough }">
                    Minimum 6 characters
                  </li>
                  <li :class="{ 'text-green-500': hasUpperCaseLetter }">
                    At least one uppercase letter
                  </li>
                  <li :class="{ 'text-green-500': hasSpecialCharacter }">
                    At least one special character
                  </li>
                </ul>
              </div>
            </div>

            <!-- Confirm Password -->
            <div class="w-full flex-col justify-start items-start gap-1.5">
              <div
                class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
              >
                Confirm Password
              </div>
              <div class="relative w-full">
                <input
                  v-model="confirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  placeholder="********"
                  :class="{
                    'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                      !confirmPassword || confirmPassword !== password,
                    'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                      confirmPassword === password,
                  }"
                />
                <img
                  @click="toggleShowConfirmPassword"
                  class="w-5 h-5 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  src="/icons/login-register/eye.svg"
                  alt="Eye Icon"
                />
              </div>
            </div>
          </div>

          <!-- Address -->
          <div class="w-full flex flex-col  gap-5">
            <div class="w-full flex flex-col md:flex-row gap-5">
              <!-- Street -->
              <div class="w-full flex-col justify-start items-start gap-1.5">
                <div
                  class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
                >
                  Street
                </div>
                <input
                  v-model="street"
                  type="text"
                  placeholder="Street address"
                  :class="{
                    'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                      !street,
                    'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                      street,
                  }"
                />
              </div>

              <!-- Apartment/Unit -->
              <div class="w-full flex-col justify-start items-start gap-1.5">
                <div
                  class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
                >
                  Apartment/Unit
                </div>
                <input
                  v-model="apartmentUnit"
                  type="text"
                  placeholder="Apartment or unit number"
                  :class="{
                    'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                      !apartmentUnit,
                    'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                      apartmentUnit,
                  }"
                />
              </div>
            </div>

            <!-- City, State, Zip Code -->
            <div class="w-full flex flex-col md:flex-row gap-5">
              <div class="w-full flex-col justify-start items-start gap-1.5">
                <div
                  class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
                >
                  City
                </div>
                <input
                  v-model="city"
                  type="text"
                  placeholder="City"
                  :class="{
                    'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                      !city,
                    'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                      city,
                  }"
                />
              </div>

              <div class="w-full flex-col justify-start items-start gap-1.5">
                <div
                  class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
                >
                  State
                </div>
                <input
                  v-model="state"
                  type="text"
                  placeholder="State"
                  :class="{
                    'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                      !state,
                    'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                      state,
                  }"
                />
              </div>

              <div class="w-full flex-col justify-start items-start gap-1.5">
                <div
                  class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
                >
                  Zip Code
                </div>
                <input
                  v-model="zipcode"
                  type="text"
                  placeholder="Zip code"
                  :class="{
                    'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                      !zipcode,
                    'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                      zipcode,
                  }"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="w-full justify-end items-center gap-3 inline-flex">
          <button
            @click="handleCancel"
            class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 flex justify-center items-center gap-2"
          >
            <div
              class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Cancel
            </div>
          </button>
          <button
            @click="handleRegister"
            :disabled="!isFormValid"
            :class="{
              'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow flex justify-center items-center gap-2':
                !isFormValid,
              'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow flex justify-center items-center gap-2':
                isFormValid,
            }"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Register
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LoaderSpinner from "../CircularLoader.vue";

export default {
  components: { LoaderSpinner },
  setup() {
    const store = useStore();
    const router = useRouter();

    const flagLoading = ref(false);
    const firstName = ref("");
    const lastName = ref("");
    const email = ref("");
    const phoneNumber = ref("");
    const phoneNumberDisplay = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const street = ref("");
    const apartmentUnit = ref("");
    const city = ref("");
    const state = ref("");
    const zipcode = ref("");
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);
    const isPasswordLongEnough = computed(() => password.value.length >= 6);
    const hasUpperCaseLetter = computed(() => /[A-Z]/.test(password.value));
    const hasSpecialCharacter = computed(() =>
      /[!@#$%^&*(),.?":{}|<>]/.test(password.value)
    );

    const isEmailValid = (email) => /\S+@\S+\.\S+/.test(email);

    function isPhoneNumberValid() {
      const re = /^\d{10}$/;
      return re.test(phoneNumber.value);
    }
    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    const toggleShowConfirmPassword = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    function updatePhoneNumber(event) {
      const input = event.target.value.replace(/\D/g, "");
      phoneNumber.value = input;
      if (input.length <= 3) {
        phoneNumberDisplay.value = input;
      } else if (input.length <= 6) {
        phoneNumberDisplay.value = `${input.slice(0, 3)}-${input.slice(3)}`;
      } else {
        phoneNumberDisplay.value = `${input.slice(0, 3)}-${input.slice(
          3,
          6
        )}-${input.slice(6)}`;
      }
    }

    const isPasswordValid = (password) => {
      const minLength = 6;
      const hasUpperCase = /[A-Z]/.test(password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      return password.length >= minLength && hasUpperCase && hasSpecialChar;
    };

    const isFormValid = computed(() => {
      return (
        firstName.value &&
        lastName.value &&
        isEmailValid(email.value) &&
        isPhoneNumberValid(phoneNumber.value) &&
        isPasswordValid(password.value) &&
        confirmPassword.value === password.value &&
        street.value &&
        apartmentUnit.value &&
        city.value &&
        state.value &&
        zipcode.value
      );
    });

    const handleRegister = () => {
      flagLoading.value = true;
      if (isFormValid.value) {
        store
          .dispatch("addAccountOwner", {
            name: firstName.value,
            last_name: lastName.value,
            email: email.value,
            phone_number: phoneNumber.value,
            password: password.value,
            address: {
              street: street.value,
              apartment_unit: apartmentUnit.value,
              city: city.value,
              state: state.value,
              zipcode: zipcode.value,
            },
          })
          .then((newAccO) => {
            firstName.value = "";
            lastName.value = "";
            email.value = "";
            password.value = "";
            confirmPassword.value = "";
            phoneNumber.value = "";
            street.value = "";
            apartmentUnit.value = "";
            city.value = "";
            state.value = "";
            zipcode.value = "";

            if (newAccO.ok) {
              flagLoading.value = false;
              router.push({ name: "login" });
            }
          })
          .catch((error) => {
            console.error("Registration error:", error);
            flagLoading.value = false;
          });
      }
    };

    const handleCancel = () => {
      firstName.value = "";
      lastName.value = "";
      email.value = "";
      password.value = "";
      confirmPassword.value = "";
      phoneNumber.value = "";
      street.value = "";
      apartmentUnit.value = "";
      city.value = "";
      state.value = "";
      zipcode.value = "";
      router.push({ name: "login" });
    };

    return {
      isPasswordLongEnough,
      hasUpperCaseLetter,
      hasSpecialCharacter,
      flagLoading,
      firstName,
      lastName,
      email,
      phoneNumberDisplay,
      password,
      confirmPassword,
      street,
      city,
      state,
      zipcode,
      isEmailValid,
      isPhoneNumberValid,
      isPasswordValid,
      updatePhoneNumber,
      isFormValid,
      handleRegister,
      handleCancel,
      apartmentUnit,
      toggleShowPassword,
      showPassword,
      toggleShowConfirmPassword,
      showConfirmPassword,
    };
  },
};
</script>
    
    <style scoped>
</style>
    
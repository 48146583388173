import api from "../../api";
export default {
  async addInvoice({ commit }, data) {
    try {
      const response = await api.post(
        "/new-kambuchi/invoices/add-invoices",
        data
      );

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding invoice",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addInvoice action:", error);
      throw error;
    }
  },

  async fetchInvoice(_, { id_invoice }) {
    try {
      const response = await api.get("/new-kambuchi/invoices/get-invoice", {
        id: id_invoice,
      });
      if (response.ok) {
        return response.invoice;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
  },

  async fetchResourcesInExpense(_, { id_expense }) {
    try {
      const response = await api.get("/new-kambuchi/users/in-expense", {
        id_expense,
      });
      if (response.ok) {
        console.log("la response de fetch resources for task", response);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async updateInvoiceStatus({ commit }, data) {
    try {
      const response = await api.put(
        `/new-kambuchi/invoices/change-status`,
        data
      );

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: response.message,
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in updateInvoiceStatus action:", error);
      throw error;
    }
  },

  async deleteInvoice({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/invoices/delete-invoice`, data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while deleting invoice",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in deleteInvoice action:", error);
      throw error;
    }
  },
};

<template>
  <LoaderSpinner v-if="flagLoading" />
  <div v-else-if="!isMobileView">
    <div
      class="w-full h-auto bg-white rounded-bl-[20px] rounded-br-[20px] justify-around items-start flex"
    >
    

      <div class="w-[50%] flex-col justify-start items-start inline-flex">
        <!-- Project Name Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Project Name
            </div>
          </div>
        </div>
        <!-- Project Name Data -->
        <div
          v-for="(item, index) in table"
          :key="'project_name-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <img
            src="/icons/projects/project-name.svg"
            class="w-10 h-10 p-2.5 bg-slate-100 rounded-[20px] justify-center items-center flex"
          />
          <div
            class="grow shrink basis-0 text-left text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.Project ? item.Project.name : "" }}
          </div>
        </div>
      </div>



      <div class="w-[25%] flex-col justify-start items-start inline-flex">
        <!-- Amount Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Amount
            </div>
          </div>
        </div>
        <!-- Total expense Data -->
        <div
          v-for="(item, index) in table"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            ${{ item.amount }}
          </div>
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Invoice date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Invoice Date
            </div>
          </div>
        </div>
        <!-- Invoice Date Data -->
        <div
          v-for="(item, index) in table"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.date) }}
          </div>
        </div>
      </div>

      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- Actions Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Actions
            </div>
          </div>
        </div>
        <!-- Actions Data -->
        <div
          v-for="(item, index) in table"
          :key="'actions-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-center items-center inline-flex"
        >
          <DropdownMenu :item="item" />
        </div>
      </div>
    </div>
    <PPagination />
  </div>
  <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
    <div
      v-for="(item, index) in table"
      :key="index"
      class="bg-white rounded-lg shadow-md p-4 flex flex-col gap-3"
    >
      <div class="flex justify-end items-end">
        <!-- <input
          type="checkbox"
          class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
        /> -->
        <DropdownMenu :item="item" />
      </div>

      <div class="flex items-center gap-3">
        <img
          src="/icons/projects/project-name.svg"
          class="w-10 h-10 p-2.5 bg-slate-100 rounded-lg"
        />
        <div class="text-slate-600 text-sm font-medium">
          Project: {{ item.Project ? item.Project.name : "" }}
        </div>
      </div>

      <!-- <div class="flex items-center gap-3">
        <div class="text-slate-600 text-sm font-medium">
          Assigned To:
          {{ item.User ? item.User.name + " " + item.User.last_name : "" }}
        </div>
        <img
          class="w-10 h-10 rounded-full"
          :src="item.User ? item.User.photo : ''"
        />
      </div> -->

      <div class="text-slate-600 text-sm text-start">
        <span class="font-medium">Amount:</span> ${{ item.amount }}
      </div>

      <div class="text-slate-600 text-sm text-start">
        <span class="font-medium">Invoice Date:</span>
        {{ helpers.formatDateToMMDDYYYY(item.date) }}
      </div>
    </div>
    <PPagination />
  </div>
</template>
  
  <script>
import PPagination from "../ProjectPagination.vue";
import DropdownMenu from "./InvoicesDropDown.vue";
import LoaderSpinner from "../../CircularLoader.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import helpers from "../../../helpers";

export default {
  components: {
    PPagination,
    DropdownMenu,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const table = computed(() => store.getters.table);
    const flagLoading = computed(() => store.getters.loading);
    const isMobileView = computed(() => store.getters.isMobileView);
    return {
      table,
      flagLoading,
      helpers,
      isMobileView,
    };
  },
};
</script>
  
  <style scoped>
</style>
  
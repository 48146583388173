<template>
  <div>
    <LoaderSpinner v-if="flagLoading" />
    <div class="table-container" v-else>
      <UserManagementSearchFilters
        @tab-selected-user-management="handleTabSelected"
        style="margin-bottom: 30px"
      />

      <UserManagementTable
        v-if="selectedTab === 'Employees'"
        style="margin-bottom: 30px"
      />
      <UserManagementTableContractors
        v-if="selectedTab === 'Contractors'"
        style="margin-bottom: 30px"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted,computed } from "vue";
import { useStore } from "vuex";
import UserManagementTable from "@/components/UserManagement/EmployeesTable.vue";
import UserManagementSearchFilters from "@/components/UserManagement/UserManagementSearchFilters.vue";
import UserManagementTableContractors from "@/components/UserManagement/ContractorsTable.vue";
import LoaderSpinner from "../components/CircularLoader.vue";

export default {
  components: {
    UserManagementTable,
    UserManagementSearchFilters,
    UserManagementTableContractors,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const selectedTab = computed(() => store.getters.selectedTab);
    const flagLoading = ref(true);

    const handleTabSelected = (tab) => {
      selectedTab.value = tab;
    };

    onMounted(async () => {
      try {
        await store.commit("clearDetails");
        await store.commit("clearPagination");
        await store.commit("clearSelectedTab");
        await store.commit("clearClients");
        await store.dispatch(
          "updateSelectedTab",
          selectedTab.value ? selectedTab.value : "Employees"
        );
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "users",
          searchType: selectedTab.value == "Employees" ? 2 : 3,
        });

        flagLoading.value = false;
      } catch (error) {
        console.error("Error in getting resources:", error);
      }
    });

 

    return {
      selectedTab,
      handleTabSelected,
      flagLoading,
    };
  },
};
</script>

<style scoped>
.table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>

<template>
  <div>
    <div
      class="w-full h-16 px-4 py-3 bg-white gap-4 flex justify-end items-center shadow-md"
    >
      <!-- <button @click="toggleDropdown" class="lg:hidden flex items-center">
        <img src="/icons/nav-bar/menu.svg" alt="Menu" class="w-6 h-6" />
      </button> -->

      <div v-if="isTimerActive" class="flex items-center gap-2">
        <img
          @click="stopTimer"
          v-if="elapsedTimeOnlySeconds > 0"
          src="/icons/global-timer/stop.svg"
          class="w-6 h-6 cursor-pointer"
        />
        <img
          @click="startTimer"
          v-if="isPaused"
          src="/icons/nav-bar/play.svg"
          class="w-6 h-6 cursor-pointer"
        />
        <img
          @click="pauseTimer"
          v-else
          src="/icons/global-timer/pause_grey.svg"
          class="w-6 h-6 cursor-pointer"
        />
        <div class="text-blue-700 text-sm font-medium">
          {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }} :
          {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }} :
          {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}
        </div>
      </div>
      <button
        v-else
        @click="toggleModalSTT"
        class="flex items-center gap-2 bg-slate-100 px-3 py-2 rounded-lg"
      >
        <img src="/icons/nav-bar/play.svg" class="w-5 h-5" />
        <span class="text-slate-500 text-sm font-medium">Time Tracker</span>
      </button>

      <div class="flex items-center gap-2">
        <button class="p-2 rounded-lg bg-slate-100">
          <img
            src="/icons/nav-bar/notification.svg"
            alt="Notifications"
            class="w-5 h-5"
          />
        </button>

        <div class="relative" ref="dropdownWrapper">
          <img
            @click="toggleDropdown"
            class="w-8 h-8 rounded-full cursor-pointer"
            :src="photo ? photo : 'https://via.placeholder.com/40x40'"
            alt="Profile"
          />

          <div
            v-if="showProfileMenu"
            class="absolute right-0 mt-2 w-36 bg-white rounded-lg shadow-lg border border-gray-200"
          >
            <ul class="py-2">
              <li
                @click="goToProfile"
                class="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              >
                Edit Profile
              </li>
              <li
                @click="toggleAuthentication"
                class="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              >
                Log Out
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <StartTimeTracker
      v-if="showModalStart"
      :isOpen="showModalStart"
      @close="showModalStart = false"
    />
    <StopTimerModal
      v-if="showModalStop"
      :isOpen="showModalStop"
      @close="showModalStop = false"
    />
  </div>
</template>
  
<script>
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import StartTimeTracker from "../GlobalTimer/StartTimeTracker.vue";
import { /*db,*/ auth } from "../../firebase/firebaseConfig.js";
// import { doc, /*onSnapshot*/ } from "firebase/firestore";
import StopTimerModal from "../GlobalTimer/StopTimer.vue";
import { useRouter } from "vue-router";
export default {
  components: {
    StartTimeTracker,
    StopTimerModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const elapsedTimeOnlySeconds = computed(
      () => store.getters.elapsedTimeOnlySeconds
    );
    const isTimerActive = computed(() => store.getters.isTimerActive);
    const showModalStart = ref(false);
    const showModalStop = ref(false);
    const currentUser = auth.currentUser;
    // const userTimerDocRef = doc(db, "user-timer", currentUser.uid);
     const isPaused = computed(() => store.getters.isPaused);
    const stopPushed = ref(null);
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const usertype = computed(() => store.state.user.type);
    const photo = computed(() =>
      store.state.user ? store.state.user.photo : ""
    );
    const isAuthenticated = computed(() => store.state.loggedUser);


    const toggleAuthentication = async () => {
      if (isAuthenticated.value) {
        await store.dispatch("logOut");
        router.push({ name: "login" });
      } else {
        router.push({ name: "login" });
      }
    };

    const showProfileMenu = ref(false); // Estado para el menú desplegable
    const dropdownWrapper = ref(null);

    const toggleModalSTT = () => {
      showModalStart.value = !showModalStart.value;
    };

    const toggleModalStop = () => {
      showModalStop.value = !showModalStop.value;
    };

    const toggleDropdown = () => {
      showProfileMenu.value = !showProfileMenu.value;
    };

    const goToProfile = () => {
      showProfileMenu.value = false;
      router.push({
        name: "edit-resource",
        params: { id: currentUser.uid },
      });
    };

    const startTimer = async () => {
      await store.dispatch("startTimer", {
        startWithTime: elapsedTimeOnlySeconds.value,
      });
      await store.dispatch("updateElapsedTime");
    };

    const pauseTimer = async () => {
      await store.dispatch("pauseTimer", { fromButton: true });
    };

    const stopTimer = async () => {
      stopPushed.value = true;
      const previousElapsedTime = elapsedTimeOnlySeconds.value;

      const data = {
        ticket: selectedTakeaway.value.ticket,
        elapsedHours: formatTime(previousElapsedTime).elapsedHours,
        elapsedMinutes: formatTime(previousElapsedTime).elapsedMinutes,
        elapsedSeconds: formatTime(previousElapsedTime).elapsedSeconds,
        isGlobal: true,
        fromButton: true,
      };

      await store.dispatch("pauseTimer", data);

      toggleModalStop();
    };

    const formatTime = (seconds) => {
      const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

      const elapsedHours = Math.floor(seconds / 3600);
      const elapsedMinutes = Math.floor((seconds % 3600) / 60);
      const elapsedSeconds = seconds % 60;

      return {
        elapsedHours: addLeadingZero(elapsedHours),
        elapsedMinutes: addLeadingZero(elapsedMinutes),
        elapsedSeconds: addLeadingZero(elapsedSeconds),
      };
    };

    const handleClickOutside = (event) => {
      if (
        dropdownWrapper.value &&
        !dropdownWrapper.value.contains(event.target)
      ) {
        showProfileMenu.value = false;
      }
    };

    onMounted(() => {
      if (isTimerActive.value) {
        isPaused.value = false;
      }

      document.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    // onSnapshot(userTimerDocRef, async (doc) => {
    //   if (doc.exists()) {
    //     const dataDoc = doc.data();
    //     console.log("🚀 ~ onSnapshot ~ dataDoc: - navbar mobile", dataDoc)
    //     const isNewPausedState = dataDoc.isTimerPaused;
    //     isPaused.value = isNewPausedState;

    //     if (dataDoc.stop) {
    //       isPaused.value = true;
    //       await store.dispatch("pauseTimer", { firestoreFlag: true });
    //       return;
    //     }
    //     if (dataDoc.isTimerActive) {
    //       console.log(' elapsed time in NavBarMobile.vue.....', elapsedTimeOnlySeconds.value);
    //       if (!isNewPausedState) {
    //         await store.dispatch("startTimer", {
    //           startWithTime: elapsedTimeOnlySeconds.value,
    //           ticket: selectedTakeaway.value.ticket,
    //           firestoreFlag: true,
    //         });
    //         await store.dispatch("updateElapsedTime");
    //       } else {
    //         await store.dispatch("pauseTimer", { firestoreFlag: true });
    //       }
    //     }
    //   }
    // });

    return {
      toggleAuthentication,
      isAuthenticated,
      toggleModalSTT,
      toggleModalStop,
      showModalStart,
      showModalStop,
      elapsedTimeOnlySeconds,
      formatTime,
      startTimer,
      pauseTimer,
      stopTimer,
      selectedTakeaway,
      isTimerActive,
      isPaused,
      toggleDropdown,
      goToProfile,
      showProfileMenu,
      usertype,
      photo,
      dropdownWrapper,
    };
  },
};
</script>
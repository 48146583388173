<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-[572px] bg-white rounded-xl shadow flex flex-col"
      v-else-if="!isMobileView"
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Start Time Tracker
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Select Project
          </div>
          <select
            v-model="selectedProject"
            class="self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex"
            @change="fetchTasks"
          >
            <option value="" disabled>Select an option</option>
            <option
              v-for="option in projects"
              :key="option"
              :value="option.Project"
            >
              {{ option.Project.name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Select Task
          </div>
          <select
            v-if="!flagTasks"
            v-model="selectedTask"
            class="self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex"
          >
            <option value="" disabled>Select an option</option>
            <option v-for="option in tasks" :key="option" :value="option">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
      <LoaderSpinner v-if="flagStartTimer" />
      <div
        v-else
        class="w-[572px] px-4 pt-2 pb-6 justify-end items-center gap-3 inline-flex"
      >
        <button
          @click="closeModal"
          class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
        >
          <div
            class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Cancel
          </div>
        </button>
        <button
          @click="addTimer"
          class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
        >
          <img
            src="/icons/projects/start_button.svg"
            class="w-5 h-5 relative"
          />
          <div
            class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Start Tracker
          </div>
        </button>
      </div>
    </div>
    <div
      v-else
      class="w-full max-w-sm bg-white rounded-xl shadow flex flex-col mx-auto"
    >
      <div
        class="p-3 bg-slate-100 rounded-t-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-base font-semibold">
          Start Time Tracker
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-full flex items-center justify-center"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <div class="p-4 flex flex-col gap-3">
        <!-- Select Project -->
        <div class="flex flex-col gap-1">
          <div class="text-slate-600 text-sm font-medium">Select Project</div>
          <select
            v-model="selectedProject"
            class="w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400"
            @change="fetchTasks"
          >
            <option value="" disabled>Select an option</option>
            <option
              v-for="option in projects"
              :key="option"
              :value="option.Project"
            >
              {{ option.Project.name }}
            </option>
          </select>
        </div>

        <!-- Select Task -->
        <div class="flex flex-col gap-1">
          <div class="text-slate-600 text-sm font-medium">Select Task</div>
          <select
            v-if="!flagTasks"
            v-model="selectedTask"
            class="w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400"
          >
            <option value="" disabled>Select an option</option>
            <option v-for="option in tasks" :key="option" :value="option">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <LoaderSpinner v-if="flagStartTimer" class="mx-auto my-4" />

      <!-- Buttons -->
      <div v-else class="w-full p-4 flex justify-end gap-2">
        <button
          @click="closeModal"
          class="px-4 py-2 bg-white rounded-lg shadow border border-slate-200 text-slate-600 text-sm font-semibold"
        >
          Cancel
        </button>
        <button
          @click="addTimer"
          class="px-4 py-2 bg-blue_primary rounded-lg shadow text-white text-sm font-semibold flex items-center gap-2"
        >
          <img src="/icons/projects/start_button.svg" class="w-5 h-5" />
          Start Tracker
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../CircularLoader.vue";

export default {
  props: {
    isOpen: Boolean,
  },
  components: { LoaderSpinner },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const flagLoading = ref(true);
    const flagTasks = ref(true);
    const flagStartTimer = ref(false);
    const isMobileView = computed(() => store.getters.isMobileView);
    const projects = ref([]);
    const selectedProject = ref("");
    const tasks = ref([]);
    const selectedTask = ref("");

    function closeModal() {
      emit("close");
    }

    async function fetchTasks() {
      await store
        .dispatch("fetchTasksForUser", {
          id_user: store.state.user.uid,
          id_project: selectedProject.value.id_project,
        })
        .then((tasksFetched) => {
          tasks.value = tasksFetched.data;
          flagTasks.value = false;
        });
    }

    async function addTimer() {
      flagStartTimer.value = true;
      const data = {
        id_user: store.state.user.uid,
        id_project: selectedProject.value.id_project,
        id_task: selectedTask.value.id_task,
      };
      await store.dispatch("startTracker", data).then(async (tracker) => {
        console.log("🚀 ~ awaitstore.dispatch ~ tracker:", tracker.data)
        if (tracker.data.existing == true) {
          console.log('existing si es true')
          const dataToStart = {
            startWithTime: tracker.data.dataValues.seconds_elapsed,
          };
          await store.dispatch("setSelectedTakeaway", {
            project_name: selectedProject.value.name,
            task_description: selectedTask.value.description,
            id_task: selectedTask.value.id_task,
            id_project: selectedProject.value.id_project,
          });
          await store.dispatch("startTimer", dataToStart);
        } else {
          console.log('existing no es true')
          await store.dispatch("setSelectedTakeaway", {
            project_name: selectedProject.value.name,
            task_description: selectedTask.value.description,
            id_task: selectedTask.value.id_task,
          });
          await store.dispatch("startTimer", {});
        }
      });
      selectedProject.value = "";
      selectedTask.value = "";
      flagStartTimer.value = false;
      closeModal();
    }

    onMounted(async () => {
      await store
        .dispatch("fetchProjectsForUser", { id_user: store.state.user.uid })
        .then((projectsFetched) => {
          projects.value = projectsFetched.data;
          flagLoading.value = false;
        });
    });

    return {
      closeModal,
      projects,
      flagLoading,
      fetchTasks,
      tasks,
      selectedProject,
      flagTasks,
      selectedTask,
      addTimer,
      flagStartTimer,
      isMobileView,
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles */
</style>
<template>
  <div>
    <LoaderSpinner v-if="flagLoading" />
    <div class="table-containerr" v-else>
      <BillsSearchFilters  :isTab="true"  style="margin-bottom: 30px" />
      <BillsTable style="margin-bottom: 30px" />
    </div>
  </div>
</template>
      
  <script>
import { ref, onMounted, } from "vue";
import BillsTable from "@/components/Projects/Bills/BillsTable.vue";
import BillsSearchFilters from "@/components/Projects/Bills/BillsSearchFilters.vue";
import { useStore } from "vuex";
import LoaderSpinner from "../components/CircularLoader.vue";

export default {
  components: {
    BillsTable,
    BillsSearchFilters,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const flagLoading = ref(true);

    onMounted(async () => {
      try {
        await store.commit("clearDetails");
        await store.commit("clearPagination");
        await store.commit("clearSelectedTab");
        await store.commit("clearClients");
        await store.commit("clearSelectedProject");
        await store.commit("setSelectedType", "Submitted");
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "bills",
          searchType: ["Submitted"],
        });
        flagLoading.value = false;
      } catch (error) {
        console.error("Error getting bills:", error);
      }
    });


    return {
      flagLoading,
    };
  },
};
</script>
  
  <style scoped>
.table-container {
  display: flex;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
  
<template>
  <LoaderSpinner v-if="flagLoading" />
  <div v-else-if="!isMobileView">
    <div
      class="w-full h-auto bg-white rounded-bl-[20px] rounded-br-[20px] justify-around items-start flex"
    >
      <!-- Headers -->
      <!-- <div class="w-[5%] flex-col justify-start items-start inline-flex"> -->
      <!-- ID Header -->
      <!-- <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-between items-center inline-flex"
        >
          <div class="justify-center items-center flex">
            <div
              class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
            ></div>
          </div> -->
      <!-- <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              ID
            </div>
          </div> -->
      <!-- </div> -->
      <!-- ID Data -->
      <!-- <div
          v-for="(item, index) in table"
          :key="index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <input
            type="checkbox"
            class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
          />
          <div
            class="grow shrink basis-0 text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.id_expense }}
          </div>
        </div>
      </div> -->

      <div class="w-[35%] flex-col justify-start items-start inline-flex">
        <!-- Expense Description Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Expense
            </div>
          </div>
        </div>
        <!-- Expense description Data -->
        <div
          v-for="(item, index) in table"
          :key="'taskName-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 flex"
        >
          <img
            src="/icons/side-bar/expenses.svg"
            class="w-10 h-10 p-2.5 bg-slate-100 rounded-[20px] justify-center items-center flex bg-[#00225E]"
          />
          <div
            class="grow shrink basis-0 text-left text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="w-[25%] flex-col justify-start items-start inline-flex">
        <!-- Assigned To Header -->
        <div
          class="self-stretch h-11 px-2 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Submitter
            </div>
          </div>
        </div>
        <!-- Assigned To Data -->
        <div
          v-for="(item, index) in table"
          :key="'assignedTo-' + index"
          class="self-stretch h-[72px] px-2 py-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div
            v-for="(item2, index2) in item.Users"
            :key="'assignedTo-' + index2"
          >
            <img class="w-10 h-10 rounded-[200px]" :src="item2.photo" />
            <div class="flex-col justify-start items-start inline-flex">
              <div
                class="text-slate-600 text-sm text-left font-normal font-['Public Sans'] leading-snug"
              >
                {{ item2.name }} {{ item2.last_name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Expense type Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Expense Type
            </div>
          </div>
        </div>
        <!-- Expense type Data -->
        <div
          v-for="(item, index) in table"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.type }}
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Picture Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Picture
            </div>
          </div>
        </div>
        <!-- Picture Data -->
        <div
          v-for="(item, index) in table"
          :key="'picture-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            v-for="(expense, index2) in (item.ExpenseFiles || []).slice(0, 5)"
            :key="index2"
            class="flex items-center gap-2"
          >
            <img :src="expense.file_url" class="w-10 h-10 rounded-full" />
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Cost Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Cost
            </div>
          </div>
        </div>
        <!-- Cost Data -->
        <div
          v-for="(item, index) in table"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            ${{ item.amount }}
          </div>
        </div>
      </div>

      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- Actions Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Actions
            </div>
          </div>
        </div>
        <!-- Actions Data -->
        <div
          v-for="(item, index) in table"
          :key="'actions-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-center items-center inline-flex"
        >
          <DropdownMenu :item="item" />
        </div>
      </div>
    </div>
    <PPagination />
  </div>
  <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
    <div
      v-for="(item, index) in table"
      :key="index"
      class="bg-white rounded-lg shadow p-4 border border-slate-200 flex flex-col gap-4"
    >
    
      <div class="flex justify-end">
        <DropdownMenu :item="item" />
      </div>

     
      <div class="flex items-center gap-4">
        <img
          src="/icons/side-bar/expenses.svg"
          class="w-12 h-12 bg-slate-100 p-2 rounded-lg bg-blue_primary"
        />
        <div class="text-slate-600 text-sm font-normal">
          {{ item.name }}
        </div>
      </div>

      <!-- Submitter -->
      <div class="flex items-center gap-3">
        <div
          v-for="(user, index2) in (item.Users || []).slice(0, 5)"
          :key="index2"
          class="flex items-center gap-2 relative"
        >
          <img
            :src="user.photo"
            class="w-10 h-10 rounded-full"
            :style="{ left: `${index2 * 14}px`, top: '0' }"
            @click="toggleTooltip(index2, item.id_expense)"
            @mouseleave="hideTooltip(item.id_expense)"
          />
          <div
            v-if="tooltipStates[item.id_expense] === index2"
            class="absolute bottom-full mb-1 left-1/2 -translate-x-1/2 whitespace-nowrap bg-black text-white text-xs py-1 px-2 rounded-md"
          >
            {{ user.name + " " + user.last_name }}
          </div>
        </div>
        <div
          v-if="item.Users && item.Users.length > 5"
          class="text-sm text-slate-500"
        >
          +{{ item.Users.length - 5 }}
        </div>
      </div>

      <!-- Tipo de gasto -->
      <div class="flex items-center gap-2">
        <div class="font-medium text-neutral-900">Expense Type:</div>
        <div class="text-slate-600 text-sm">{{ item.type }}</div>
      </div>

      <!-- Imagen del gasto -->
      <div class="flex items-center gap-2">
        <div class="font-medium text-neutral-900">Picture:</div>
        <div
          v-for="(expense, index2) in (item.ExpenseFiles || []).slice(0, 5)"
          :key="index2"
          class="flex items-center gap-2"
        >
          <img :src="expense.file_url" class="w-10 h-10 rounded-full" />
        </div>
      </div>

      <!-- Costo -->
      <div class="flex items-center gap-2">
        <div class="font-medium text-neutral-900">Cost:</div>
        <div class="text-slate-600 text-sm">${{ item.amount }}</div>
      </div>
    </div>
    <PPagination />
  </div>
</template>
  
  <script>
import PPagination from "../ProjectPagination.vue";
import DropdownMenu from "./ExpensesDropDown.vue";
import LoaderSpinner from "../../CircularLoader.vue";
import helpers from "../../../helpers";
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    PPagination,
    DropdownMenu,
    LoaderSpinner,
  },

  setup() {
    const store = useStore();
    const table = computed(() => store.getters.table);
    const flagLoading = computed(() => store.getters.loading);
    const isMobileView = computed(() => store.getters.isMobileView);
    const tooltipStates = ref({});

    const toggleTooltip = (index, itemId) => {
      tooltipStates.value[itemId] =
        tooltipStates.value[itemId] === index ? null : index;
    };

    const hideTooltip = (itemId) => {
      tooltipStates.value[itemId] = null;
    };
    return {
      table,
      flagLoading,
      helpers,
      isMobileView,
      tooltipStates,
      toggleTooltip,
      hideTooltip,
    };
  },
};
</script>
  
  <style scoped>
</style>
  
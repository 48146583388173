export default {
  setClients(state, clients) {
    state.pagination.clients = clients;
  },
  clearClients(state) {
    state.pagination.clients = [];
  },
  setPagination(state, pagination) {
    state.pagination.pagination = pagination;
  },
  clearPagination(state) {
    state.pagination.pagination = {
      // totalItems: 0,
      // totalPages: 0,
      // currentPage: 1,
      // loading: false,
    };
  },
  setLoading(state, isLoading) {
    state.pagination.loading = isLoading;
  },
  setSelectedTab(state, tab) {
    state.pagination.selectedTab = tab;
  },
  clearSelectedTab(state) {
    state.pagination.selectedTab = null;
  },
  setSelectedType(state, type) {
    state.pagination.selectedType = type;
  },
  clearSelectedType(state) {
    state.pagination.selectedType = null;
  },
};

<template>
  <LoaderSpinner v-if="flagLoading" />
  <div
    v-else-if="!isMobileView"
    class="w-[50%] h-auto p-4 bg-white rounded-[20px] shadow flex-col justify-start items-start gap-5 inline-flex"
  >
    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Create New Project
      </div>
    </div>
    <div class="self-stretch justify-between items-center inline-flex">
      <div
        class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Client Information
      </div>
    </div>
    <div class="self-stretch justify-center items-center gap-5 inline-flex">
      <div
        class="w-[552px] h-[74px] flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Client
        </div>
        <div class="w-full">
          <div class="flex items-center gap-3">
            <img
              class="w-9 h-9 rounded-full"
              v-if="project_id_client"
              :src="project_id_client.photo"
            />
            <select
              id="client-select"
              v-model="project_id_client"
              :class="{
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !project_id_client,
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  project_id_client,
              }"
            >
              <option value="" disabled>Select a client</option>
              <option
                v-for="client in details"
                :key="client.id_client"
                :value="client"
              >
                {{ client.name }} {{ client.last_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Project Information
      </div>
    </div>
    <div
      class="w-full h-[74px] flex-col justify-start items-start gap-1.5 flex"
    >
      <div
        class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Project Title
        </div>
        <input
          v-model="project_name"
          type="text"
          placeholder="Title"
          :class="{
            'w-full capitalize self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !project_name,
            'w-full self-stretch capitalize px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              project_name,
          }"
        />
      </div>
    </div>
    <div
      class="self-stretch h-[152px] flex-col justify-start items-start gap-1.5 flex"
    >
      <div
        class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Description
      </div>
      <textarea
        v-model="description"
        rows="4"
        placeholder="Enter description"
        :class="{
          'w-full self-stretch capitalize px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
            !description,
          'w-full self-stretch capitalize px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
            description,
        }"
      />
    </div>

    <div
      class="w-full h-[74px] flex-col justify-start items-start gap-1.5 flex"
    >
      <div
        class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Status
        </div>
        <select
          v-model="status"
          :class="{
            'self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !status,
            'self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              status,
          }"
        >
          <option value="" disabled>Select an option</option>
          <option value="Active">Active</option>
          <option value="In Progress">In Progress</option>
          <option value="Cancel">Cancel</option>
        </select>
      </div>
    </div>

    <div class="w-full flex justify-between gap-5">
      <!-- Street -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Street
        </div>
        <input
          v-model="street"
          type="text"
          placeholder="Street address"
          :class="{
            'w-full capitalize px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !street,
            'w-full capitalize px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              street,
          }"
        />
      </div>

      <!-- Apartment/Unit -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Apartment/Unit
        </div>
        <input
          v-model="apartmentUnit"
          type="text"
          placeholder="Apartment or unit number"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !apartmentUnit,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              apartmentUnit,
          }"
        />
      </div>
    </div>

    <div class="w-full flex justify-between gap-5">
      <!-- City -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          City
        </div>
        <input
          v-model="city"
          type="text"
          placeholder="City"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !city,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              city,
          }"
        />
      </div>

      <!-- State -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          State
        </div>
        <input
          v-model="state"
          type="text"
          placeholder="State"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !state,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              state,
          }"
        />
      </div>

      <!-- Zipcode -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Zip Code
        </div>
        <input
          v-model="zipcode"
          type="text"
          placeholder="Zip code"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !zipcode,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              zipcode,
          }"
        />
      </div>
    </div>
    <div class="self-stretch justify-start items-start gap-5 inline-flex">
      <div
        class="grow shrink basis-0 h-[74px] flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Rate Type
          </div>

          <input
            v-model="rate_type"
            type="number"
            placeholder="hourly"
            :class="{
              'w-full px-3 capitalize py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !rate_type,
              'w-full px-3 capitalize py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                rate_type,
            }"
          />
        </div>
      </div>
      <div
        class="grow shrink basis-0 h-[74px] flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Time Allocated (Hours)
          </div>

          <input
            v-model="time_allocated"
            type="number"
            placeholder="100"
            :class="{
              'w-full px-3 capitalize py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !time_allocated,
              'w-full px-3 capitalize py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                time_allocated,
            }"
          />
        </div>
      </div>
    </div>
    <div
      class="self-stretch pb-6 border-b border-slate-200 justify-end items-start gap-5 inline-flex"
    >
      <div
        class="grow shrink basis-0 h-[74px] flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Start Date
          </div>
          <div class="relative w-full">
            <input
              type="date"
              v-model="start_date"
              placeholder="dd/mm/yyyy"
              :class="{
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !start_date,
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  start_date,
              }"
            />
          </div>
        </div>
      </div>
      <div
        class="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Due Date
          </div>
          <div class="relative w-full">
            <input
              type="date"
              v-model="due_date"
              placeholder="dd/mm/yyyy"
              :class="{
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !due_date,
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  due_date,
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="w-[1124px] max-w-full justify-end items-center gap-3 flex">
      <button
        @click="handleCancel()"
        class="px-2 py-2 bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
      >
        <div
          class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Cancel
        </div>
      </button>
      <button
        @click="handleCreateProject()"
        :class="
          !isFormValid
            ? 'px-2 py-2 bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex'
            : 'px-2 py-2 bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex'
        "
      >
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Create Project
        </div>
      </button>
    </div>
  </div>
  <div
    v-else
    class="w-full h-auto p-4 bg-white rounded-[20px] shadow flex-col justify-start items-start gap-5 inline-flex"
  >
    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Create New Project
      </div>
    </div>

    <!-- Client Information -->
    <div class="self-stretch justify-start items-center inline-flex">
      <div
        class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Client Information
      </div>
    </div>
    <div class="self-stretch justify-center items-center gap-5 inline-flex">
      <div
        class="w-full h-[74px] flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Client
        </div>
        <div class="w-full">
          <div class="flex items-center gap-3">
            <img
              class="w-9 h-9 rounded-full"
              v-if="project_id_client"
              :src="project_id_client.photo"
            />
            <select
              id="client-select"
              v-model="project_id_client"
              :class="{
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !project_id_client,
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  project_id_client,
              }"
            >
              <option value="" disabled>Select a client</option>
              <option
                v-for="client in details"
                :key="client.id_client"
                :value="client"
              >
                {{ client.name }} {{ client.last_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Project Information
      </div>
    </div>

    <!-- Project Title -->
    <div class="w-full flex-col justify-start items-start gap-1.5 flex">
      <div class="self-stretch flex-col justify-start items-start gap-1.5 flex">
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Project Title
        </div>
        <input
          v-model="project_name"
          type="text"
          placeholder="Title"
          :class="{
            'w-full capitalize self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !project_name,
            'w-full self-stretch capitalize px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              project_name,
          }"
        />
      </div>
    </div>

    <!-- Description -->
    <div
      class="self-stretch h-[152px] flex-col justify-start items-start gap-1.5 flex"
    >
      <div
        class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Description
      </div>
      <textarea
        rows="4"
        v-model="description"
        placeholder="Enter description"
        :class="{
          'w-full self-stretch capitalize px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
            !description,
          'w-full self-stretch capitalize px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
            description,
        }"
      />
    </div>

    <!-- Status -->
    <div
      class="w-full h-[74px] flex-col justify-start items-start gap-1.5 flex"
    >
      <div
        class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Status
      </div>
      <select
        v-model="status"
        :class="{
          'self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
            !status,
          'self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
            status,
        }"
      >
        <option value="" disabled>Select an option</option>
        <option value="Active">Active</option>
        <option value="In progress">In progress</option>
        <option value="Cancel">Cancel</option>
      </select>
    </div>

    <!-- Address Information -->

    <div class="w-full flex flex-col gap-5">
      <!-- Street -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Street
        </div>
        <input
          v-model="street"
          type="text"
          placeholder="Street address"
          :class="{
            'w-full capitalize px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !street,
            'w-full capitalize px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              street,
          }"
        />
      </div>

      <!-- Apartment/Unit -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Apartment/Unit
        </div>
        <input
          v-model="apartmentUnit"
          type="text"
          placeholder="Apartment or unit number"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !apartmentUnit,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              apartmentUnit,
          }"
        />
      </div>

      <div class="w-full flex flex-col gap-5">
        <!-- City -->
        <div class="w-full flex-col justify-start items-start gap-1.5">
          <div
            class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            City
          </div>
          <input
            v-model="city"
            type="text"
            placeholder="City"
            :class="{
              'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !city,
              'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                city,
            }"
          />
        </div>

        <!-- State -->
        <div class="w-full flex-col justify-start items-start gap-1.5">
          <div
            class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            State
          </div>
          <input
            v-model="state"
            type="text"
            placeholder="State"
            :class="{
              'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !state,
              'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                state,
            }"
          />
        </div>

        <!-- Zipcode -->
        <div class="w-full flex-col justify-start items-start gap-1.5">
          <div
            class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Zip Code
          </div>
          <input
            v-model="zipcode"
            type="text"
            placeholder="Zip code"
            :class="{
              'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !zipcode,
              'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                zipcode,
            }"
          />
        </div>
      </div>

      <!-- Rate Type and Time Allocated -->
      <div class="w-full flex flex-col gap-5">
        <div class="w-full flex-col justify-start items-start gap-1.5">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Rate Type
          </div>
          <input
            v-model="rate_type"
            type="number"
            placeholder="hourly"
            :class="{
              'w-full px-3 capitalize py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !rate_type,
              'w-full px-3 capitalize py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                rate_type,
            }"
          />
        </div>
        <div class="w-full flex-col justify-start items-start gap-1.5">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Time Allocated (Hours)
          </div>
          <input
            v-model="time_allocated"
            type="number"
            placeholder="100"
            :class="{
              'w-full px-3 capitalize py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !time_allocated,
              'w-full px-3 capitalize py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                time_allocated,
            }"
          />
        </div>
      </div>

      <!-- Start Date and Due Date -->
      <div class="w-full flex flex-col gap-5">
        <div class="w-full flex-col justify-start items-start gap-1.5">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Start Date
          </div>
          <input
            type="date"
            v-model="start_date"
            placeholder="dd/mm/yyyy"
            :class="{
              'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !start_date,
              'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                start_date,
            }"
          />
        </div>
        <div class="w-full flex-col justify-start items-start gap-1.5">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Due Date
          </div>
          <input
            type="date"
            v-model="due_date"
            placeholder="dd/mm/yyyy"
            :class="{
              'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !due_date,
              'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                due_date,
            }"
          />
        </div>
      </div>

      <!-- Buttons -->
      <div class="w-full flex justify-between gap-5">
        <button
          @click="handleCancel()"
          class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
        >
          <div
            class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Cancel
          </div>
        </button>
        <button
          @click="handleCreateProject()"
          :class="
            !isFormValid
              ? 'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex'
              : 'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex'
          "
        >
          <div
            class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Create Project
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LoaderSpinner from "../CircularLoader.vue";

export default {
  components: {
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const project_name = ref("");
    const start_date = ref("");
    const due_date = ref("");
    const status = ref("");
    const street = ref("");
    const apartmentUnit = ref("");
    const city = ref("");
    const state = ref("");
    const zipcode = ref("");
    const description = ref("");
    const rate_type = ref("");
    const time_allocated = ref("");
    const project_id_client = ref("");
    const isMobileView = computed(() => store.getters.isMobileView);
    const flagLoading = ref(false);
    const details = computed(() => store.getters.detailsData);
    const isFormValid = computed(() => {
      return (
        project_name.value &&
        project_id_client.value &&
        start_date.value &&
        due_date.value &&
        status.value &&
        street.value &&
        city.value &&
        state.value &&
        zipcode.value &&
        description.value &&
        rate_type.value &&
        time_allocated.value
      );
    });

    function handleCreateProject() {
      if (!isFormValid.value) {
        return;
      }

      try {
        flagLoading.value = true;
        const projectData = {
          name: project_name.value,
          id_client: project_id_client.value.id_client,
          start_date: start_date.value,
          due_date: due_date.value,
          status: status.value,
          address: {
            street: street.value,
            apartment_unit: apartmentUnit.value,
            city: city.value,
            state: state.value,
            zipcode: zipcode.value,
          },
          description: description.value,
          rate_type: rate_type.value,
          time_allocated: time_allocated.value,
        };

        store.dispatch("addProject", projectData).then((newProject) => {
          project_name.value = "";
          project_id_client.value = "";
          due_date.value = "";
          status.value = "";
          street.value = "";
          apartmentUnit.value = "";
          city.value = "";
          state.value = "";
          zipcode.value = "";
          description.value = "";
          rate_type.value = "";
          time_allocated.value = "";

          if (newProject.ok) {
            flagLoading.value = false;
            router.push({ name: "projects" });
          } else {
            flagLoading.value = false;
          }
        });
      } catch (error) {
        flagLoading.value = false;
        console.error("Error creating project:", error);
      }
    }

    function handleCancel() {
      project_name.value = "";
      project_id_client.value = "";
      due_date.value = "";
      status.value = "";
      street.value = "";
      apartmentUnit.value = "";
      city.value = "";
      state.value = "";
      zipcode.value = "";
      description.value = "";
      rate_type.value = "";
      time_allocated.value = "";
      router.push({ name: "projects" });
    }

    onMounted(async () => {
      flagLoading.value = true;
      await store.dispatch("fetchAllClients");
      flagLoading.value = false;
    });

    return {
      project_name,
      project_id_client,
      start_date,
      due_date,
      status,
      street,
      apartmentUnit,
      city,
      state,
      zipcode,
      description,
      rate_type,
      time_allocated,
      isFormValid,
      handleCreateProject,
      flagLoading,
      handleCancel,
      details,
      isMobileView,
    };
  },
};
</script>
